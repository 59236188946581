import { render, staticRenderFns } from "./residential-campaign.vue?vue&type=template&id=06cc8789&scoped=true&"
import script from "./residential-campaign.vue?vue&type=script&lang=ts&"
export * from "./residential-campaign.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06cc8789",
  null
  
)

export default component.exports