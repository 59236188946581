









































































import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

export default Vue.extend({
  components: { FontAwesomeIcon },
  computed: {
    faPhone() {
      return faPhone;
    },
    faEnvelope() {
      return faEnvelope;
    },
    faCopyright() {
      return faCopyright;
    },
    currentYear() {
      return new Date().getFullYear();
    }
  }
});
