



























































































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { SectionHeader } from '~/components/widgets';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

@Component({
  components: { SectionHeader, ValidationObserver, ValidationProvider }
})
export default class MontrealAcquisitionForm extends Vue {
  firstName: string = '';
  lastName: string = '';
  companyName: string = '';
  serviceAddress: string = '';
  email: string = '';
  telephone: string = '';
  helpOptions = [];
  message: string = '';
  subscribe: boolean = false;
  submitSuccessful: boolean | null = null;
  $refs: {
    formRef;
  };

  submitForm() {
    this['$axios']
      .post('/business/montreal-acquisition-form', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        serviceAddress: this.serviceAddress,
        companyName: this.companyName,
        telephone: this.telephone,
        helpOptions: this.helpOptions.toString(),
        message: this.message,
        subscribe: this.subscribe
      })
      .then(res => {
        if (res.data) {
          if (res.data.successful) {
            this.firstName = this.lastName = this.companyName = this.email = '';
            this.telephone = this.message = '';
            this.subscribe = false;
            this.submitSuccessful = true;
            let action = 'Sent Montreal Acquisition Form';

            this['$ga'].event({
              eventCategory: 'Montreal Acquisition Contact Form',
              eventAction: action,
              eventLabel: this['$route'].path
            });

            this.$emit('formsubmitted', '');
          } else if (res.data.error) {
            this.$refs.formRef.setErrors(res.data.error_dict);
          } else {
            this.submitSuccessful = false;
          }
        } else {
          this.submitSuccessful = false;
        }
      })
      .catch(() => {
        this.submitSuccessful = false;
      });
  }
}
