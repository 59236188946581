




















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class Slogan extends Vue {
  @Prop()
  title: string;
  @Prop()
  subtitle: string;
  @Prop({ default: 'h3' })
  titleTag: string;
}
