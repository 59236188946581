







































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import startsWith from 'lodash/startsWith';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Component({
  components: { FontAwesomeIcon }
})
export default class IconBox extends Vue {
  @Prop({ required: true })
  title: string;
  @Prop({ required: true })
  link: string;
  @Prop({ required: false })
  description: string;
  @Prop({ default: false })
  disabled: boolean;
  @Prop({ default: 'h2' })
  titleEl: string;
  @Prop({ default: '' })
  linkClass: string;

  emitClick() {
    this.$emit('click');
  }
  linkEl() {
    return startsWith(this.link, '/') ? 'nuxt-link' : 'a';
  }
}
