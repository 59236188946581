









































































































































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Watch } from 'vue-property-decorator';
import { SectionHeader } from '~/components/widgets';
import { Debounce } from '~/libraries/requests';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Mutation } from 'vuex-class';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { AddressSearchResponse } from '~/types/forms';
import { AxiosResponse } from 'axios';
import getUTMData from '~/libraries/utm';
@Component({
  components: { SectionHeader, ValidationObserver, ValidationProvider },
  computed: {
    open: {
      get() {
        return this.$store.state.businessForm.open;
      },
      set() {
        return this.$store.state.businessForm.open;
      }
    }
  }
})
export default class BusinessContactSideForm extends Vue {
  product: String = '';
  isOpen: boolean = false;
  isFetching: boolean = false;
  reduce: boolean = false;
  firstName: string = '';
  lastName: string = '';
  companyName: string = '';
  serviceAddress: string = '';
  email: string = '';
  telephone: string = '';
  helpOptions = [];
  dataAddress: any = [];
  selected: null;
  message: string = '';
  subscribe: boolean = false;
  apiKey: string = '';
  submitSuccessful: boolean | null = null;
  captchaError: boolean = false;
  $refs: {
    formRef;
    firstName;
  };
  get faArrowRight() {
    return faArrowRight;
  }
  @Mutation('businessForm/setSideFormState')
  setSideFormState;

  @Mutation('businessForm/getCanadaPostApiKey')
  getCanadaPostApiKey;
  async mounted() {
    this.getCanadaPostApiKey();
    this.apiKey = this.$store.state.businessForm.apiKey;
  }

  @Watch('$store.state.businessForm.open')
  onChangeFormOpen() {
    const isOpen = this.$store.state.businessForm.open;
    if (isOpen) {
      const firstInput = this.$refs.firstName;
      this.$nextTick(function() {
        firstInput.focus();
      });
    }
  }

  setOpenState() {
    this.setSideFormState(true);
  }
  closeSideBar() {
    this.setSideFormState(false);
    this.isOpen = true;
    let action = 'Closed SideBar';
    this['$ga'].event({
      eventCategory: 'Form slide-out',
      eventAction: action,
      eventLabel: this['$route'].path
    });
    this.submitSuccessful = null;
  }
  @Debounce(500)
  async searchAddress(): Promise<void> {
    const response: AxiosResponse<
      AddressSearchResponse
    > = await this.$axios.get(
      'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws',
      {
        params: {
          Key: this.apiKey,
          SearchTerm: this.serviceAddress,
          Country: 'CAN',
          MaxSuggestions: 6
        }
      }
    );
    this.dataAddress = [];
    let serviceAddress = response.data.Items;
    if (serviceAddress.length >= 1 && serviceAddress[0].Error === undefined) {
      serviceAddress.forEach(element =>
        this.dataAddress.push(element.Text + ' ' + element.Description)
      );
    }
  }

  async submitForm() {
    this['$axios']
      .post(
        '/business/contact-form',
        {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          serviceAddress: this.serviceAddress,
          companyName: this.companyName,
          telephone: this.telephone
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          helpOptions: this.helpOptions.join(', '),
          message: this.message,
          subscribe: this.subscribe,
          language: this.$i18n.locale,
          ...getUTMData()
        },
        {
          params: {
            form: 'side'
          }
        }
      )
      .then(res => {
        if (res.data) {
          if (res.data.successful) {
            this.firstName = this.lastName = this.companyName = this.email = '';
            this.telephone = this.serviceAddress = this.message = '';
            this.helpOptions = [];
            this.subscribe = false;
            this.submitSuccessful = true;
            let action = 'Sent Business Side Form';

            this['$ga'].event({
              eventCategory: 'Business Contact Side Form',
              eventAction: action,
              eventLabel: this['$route'].path
            });

            this.$emit('formsubmitted');
          } else if (res.data.error) {
            this.$refs.formRef.setErrors(res.data.error_dict);
          } else {
            this.submitSuccessful = false;
          }
        } else {
          this.submitSuccessful = false;
        }
      })
      .catch(() => {
        this.submitSuccessful = null;
      });
  }
}
