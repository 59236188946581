
















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class CiscoCollaborationBox extends Vue {
  @Prop()
  title: string;
  isOpen = false;

  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  arrowIcon() {
    if (this.isOpen) {
      return faAngleUp;
    } else {
      return faAngleDown;
    }
  }
}
