




















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class ServiceBox extends Vue {
  @Prop({ required: true })
  boxTitle: string;
  @Prop({ required: true })
  boxLink: string;
  @Prop()
  title: string;
}
