




















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionHeader } from '~/components/widgets';

@Component({
  components: { SectionHeader }
})
export default class TwoColumnBox extends Vue {
  @Prop()
  headerTitle: string;
  @Prop()
  extraClass: string;
}
