





























import Vue from 'vue';
import Component from 'nuxt-class-component';

import {
  Navbar,
  BusinessFooter,
  SiteNav,
  BusinessNavbar,
  BusinessSiteNav
} from '~/components/layout';

@Component({
  components: {
    Navbar,
    BusinessFooter,
    SiteNav,
    BusinessNavbar,
    BusinessSiteNav
  }
})
export default class extends Vue {
  middleware() {
    return 'utm';
  }
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${process.env.NUXT_ENV_BASE_URL}${this.$route.fullPath}/`
        }
      ]
    };
  }
}
