













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { LocaleMessage } from '~/node_modules/vue-i18n';

@Component({})
export default class PostalCodeInput extends Vue {
  @Prop()
  value: string;
  title: LocaleMessage = '';
  placeholder: LocaleMessage = '';
  postalCodeRegex: RegExp = /[A-Z0-9]$/i;

  $refs: {
    input: HTMLFormElement;
  };
  mounted() {
    this.title = this.$t('components:inputs:postalcodeinput:title');
    this.placeholder = this.$t('components:forms:business-searchform:search');
  }
  @Watch('$i18n.locale')
  onLangChange() {
    this.title = this.$t('components:inputs:postalcodeinput:title');
    this.placeholder = this.$t('components:forms:business-searchform:search');
  }

  updateValue(value) {
    let formattedValue = this.formatPostalCode(value);
    if (formattedValue !== value) {
      this.$refs.input.value = formattedValue;
    }
    this.$emit('input', formattedValue);
  }
  changeFocusPlaceholder() {
    this.placeholder = this.$t(
      'components:forms:business-searchform:search-focused'
    );
  }
  changeBlurPlaceholder() {
    this.placeholder = this.$t('components:forms:business-searchform:search');
  }
  formatPostalCode(value): string {
    let formattedValue = value.trim().toUpperCase();
    const length = formattedValue.length;
    if (!this.postalCodeRegex.test(formattedValue)) {
      return formattedValue.slice(0, length - 1);
    }
    if (length > 3 && formattedValue.indexOf(' ') === -1) {
      formattedValue =
        formattedValue.slice(0, 3) + ' ' + formattedValue.slice(3, 6);
    }

    return formattedValue;
  }
}
