

















































































import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'nuxt-class-component';
import { Testimonial } from '~/plugins/api';
import { Carousel, Slide } from '~/components/widgets';

@Component({
  components: {
    Carousel,
    Slide
  }
})
export default class Testimonials extends Vue {
  itemsPerRow = 3;
  itemsPerRowTablet = 2;
  itemsPerRowMobile = 1;
  @Prop()
  testimonialsList: Array<Testimonial>;

  get rowCount() {
    return Math.ceil(this.testimonialsList.length / this.itemsPerRow);
  }
  get rowCountTablet() {
    return Math.ceil(this.testimonialsList.length / this.itemsPerRowTablet);
  }
  get rowCountMobile() {
    return Math.ceil(this.testimonialsList.length / this.itemsPerRowMobile);
  }

  itemCountInRow(index) {
    return this.testimonialsList.slice(
      (index - 1) * this.itemsPerRow,
      index * this.itemsPerRow
    );
  }
  itemCountInRowTablet(index) {
    return this.testimonialsList.slice(
      (index - 1) * this.itemsPerRowTablet,
      index * this.itemsPerRowTablet
    );
  }
  itemCountInRowMobile(index) {
    return this.testimonialsList.slice(
      (index - 1) * this.itemsPerRowMobile,
      index * this.itemsPerRowMobile
    );
  }
}
