import Vue from 'vue';

if (process.browser) {
  window['DESKPRO_WIDGET_OPTIONS'] = {
    widget: {
      type: 'column',
      position: 'right',
      enabled: true
    },
    chat: {
      request_user_info: true,
      proactive: false,
      popup: {
        translations: [
          {
            language: 1,
            title: 'Customer Support',
            message:
              'Need help? Just reply to start a live chat with one of our team.',
            heading: 'Ask us a question!',
            subheading:
              'Our team is online and ready to help with your enquiries. Send us a message to get started.',
            start_button: 'Start a conversation'
          }
        ],
        style: 'text_button'
      },
      begin_mode: 'form',
      allow_department_selection: true,
      waiting_timeout: 150
    },
    ticket: {
      select_department: 'custom',
      select_subject: 'custom',
      default_subject: ''
    },
    noFetchOptions: true,
    helpdeskUrl: 'https://helpdesk.beanfield.com/',
    baseUrl: 'https://helpdesk.beanfield.com/'
  };
}

// Load the dp-widget-loader after mount to ensure the window has the correct settings.
Vue.component('deskpro', {
  mounted: function() {
    if (window.matchMedia('(min-device-width: 769px)').matches) {
      let dpLoader = document.createElement('script');
      dpLoader.setAttribute('src', 'https://helpdesk.beanfield.com/dyn-assets/pub/build/widget_loader.min.js');
      dpLoader.id = 'dp-widget-loader';
      dpLoader.setAttribute('async', '');
      document.head.appendChild(dpLoader);
    }
  },
  render: function(createElement) {
    return createElement('div');
  }
});

function withinSupportHours() {
  const currentTime = new Date();
  const day = currentTime.getDay();
  let beforeHour = new Date();
  let afterHour = new Date();

  if (day > 0 && day < 6) {
    beforeHour.setHours(9);
    beforeHour.setMinutes(0);
    afterHour.setHours(20);
    afterHour.setMinutes(55);
  } else {
    beforeHour.setHours(10);
    beforeHour.setMinutes(0);
    afterHour.setHours(17);
    afterHour.setMinutes(55);
  }

  return currentTime >= beforeHour && currentTime <= afterHour;
}
function openChat() {
  // Trigger a click event on the hidden deskpro chat now button since the dp loader
  // only listens on buttons that exist on load, and misses buttons created on route
  // changes.

  /** Pretend this doesn't exist **/
  let dpFrame = document.querySelector('[name="widget_trigger_iframe"]');
  if (dpFrame) {
    let chatButton = dpFrame['contentWindow'].document.querySelector(
      '.preemtive-button'
    );
    if (chatButton) {
      chatButton.click();
    }
  }
}
Vue.directive('deskpro-button', {
  bind: function(el) {
    if (!withinSupportHours()) {
      el.setAttribute('disabled', '');
      el.classList.add('is-disabled');
    } else {
      el.addEventListener('click', openChat);
    }
  }
});
