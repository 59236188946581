export default function(context) {
  const persistUTMData = () => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams) {
      if (urlParams.get('utm_campaign') !== null)
        sessionStorage.setItem('utm_campaign', urlParams.get('utm_campaign'));
      if (urlParams.get('utm_source') !== null)
        sessionStorage.setItem('utm_source', urlParams.get('utm_source'));
      if (urlParams.get('utm_medium') !== null)
        sessionStorage.setItem('utm_medium', urlParams.get('utm_medium'));
      if (urlParams.get('utm_language') !== null)
        sessionStorage.setItem('utm_language', urlParams.get('utm_language'));
      if (urlParams.get('utm_content') !== null)
        sessionStorage.setItem('utm_content', urlParams.get('utm_content'));
      if (urlParams.get('gclid') !== null)
        sessionStorage.setItem('gclid', urlParams.get('gclid'));
    }
  };

  if (process.client) persistUTMData();
}
