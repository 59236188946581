
























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class PartnersImageBox extends Vue {
  @Prop({ default: '#215420' })
  color: string;

  @Prop({ default: '0' })
  paddingBottom: string;

  @Prop({ default: '0' })
  paddingTop: string;

  @Prop({ default: true })
  imageTop: boolean;

  @Prop({ default: false })
  lightFont: boolean;

  @Prop()
  boxTitle: string;

  @Prop()
  boxDescription: string;

  boxStyle = {
    backgroundColor: ''
  };

  boxBodyStyle = {
    paddingTop: '0',
    paddingBottom: '0'
  };

  fontStyle = {
    color: '#4B4A4A'
  };

  mounted() {
    this.boxStyle.backgroundColor = this.color;
    this.boxBodyStyle.paddingTop = this.paddingTop;
    this.boxBodyStyle.paddingBottom = this.paddingBottom;

    if (this.lightFont) {
      this.fontStyle.color = '#FFFFFF';
    }
  }
}
