











































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

import ArrowButton from '~/components/widgets/ArrowButton.vue';
import ContactUs from '~/components/content/ContactUs.vue';

@Component({
  components: { ArrowButton, ContactUs }
})
export default class extends Vue {
  @Prop()
  error;

  get statusCode() {
    return this.error.statusCode;
  }
  get statusMessage() {
    if (this.statusCode === 404) {
      return 'Page Not Found';
    } else if (this.statusCode === 503) {
      return 'Service Not Available';
    }
  }

  mounted() {
    window.scrollTo(0, 0);
  }
  layout() {
    return 'splash'; // Not working sometimes, should be fixed by nuxt 2
  }
}
