




































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class extends Vue {
  showDropdown: boolean = false;
  servicePathMatched: boolean = false;
  showESGPage: boolean = process.env.FEATURE_FLAG_ESG === 'true';

  @Watch('$route')
  checkServices(to) {
    this.checkServicesRouteActive(to);
    this.showDropdown = false;
  }

  mounted() {
    this.checkServicesRouteActive(this.$route);
  }
  // Main path needs custom check to show as active as supports shares same base url
  checkServicesRouteActive(route) {
    const path: string = route.path;
    this.servicePathMatched =
      path.indexOf('/residential') !== -1 && path.indexOf('support') === -1;
  }
}
