














import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class extends Vue {
  @Prop()
  links: Array<any>;

  updateHash(el) {
    this.$router.replace({ hash: el.id });
  }
  mounted() {
    const hash = this.$route.hash;
    if (hash && hash.length > 1) {
      this['$scrollTo'](hash);
    }
  }
}
