export default function getUTMData() {
  const utmKeys = [
    'utm_campaign',
    'utm_source',
    'utm_medium',
    'utm_language',
    'utm_content',
    'gclid'
  ];
  const utmData = {};
  for (const utmKey of utmKeys) {
    const item = sessionStorage.getItem(utmKey);
    if (item !== null || item !== 'null') {
      utmData[utmKey] = item;
    }
  }
  return utmData;
}
