






































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';

import { PostalCodeInput } from '~/components/inputs';
import { IconLink } from '~/components/widgets';

@Component({
  components: { PostalCodeInput, IconLink },
  computed: {
    faPhone() {
      return faPhone;
    },
    faEnvelope() {
      return faEnvelope;
    },
    faCommentDots() {
      return faCommentDots;
    }
  }
})
export default class ContactForm extends Vue {
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  telephone: string = '';
  postalCode: string = '';
  message: string = '';
  submitSuccessful: boolean | null = null;

  submitForm() {
    this['$axios']
      .post('/residential/contact-form', {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        telephone: this.telephone,
        postalCode: this.postalCode,
        message: this.message
      })
      .then(res => {
        if (res.data) {
          this.firstName = this.lastName = this.email = '';
          this.telephone = this.postalCode = this.message = '';
          this.submitSuccessful = true;
          this['$ga'].event({
            eventCategory: 'Contact Form',
            eventAction: 'Sent',
            eventLabel: this['$route'].path
          });
        } else {
          this.submitSuccessful = false;
        }
      })
      .catch(() => {
        this.submitSuccessful = false;
      });
  }
}
