



























































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

import ServiceBox from '~/components/widgets/ServiceBox.vue';

@Component({
  components: { ServiceBox }
})
export default class extends Vue {
  @Prop({
    validator: value => {
      return ['internet', 'tv', 'phone'].indexOf(value) !== -1;
    },
    required: true
  })
  currentPage: string;
}
