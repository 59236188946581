


























































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Mutation } from 'vuex-class';
import {
  BusinessSearchResultsPopup,
  ContactUsBusiness
} from '~/components/content';
import {
  BusinessAddressInput,
  BusinessPostalCodeInput
} from '~/components/inputs';
import { Address, BuildingStatus } from '~/plugins/api';
import { TranslateResult } from '~/node_modules/vue-i18n';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    FontAwesomeIcon,
    BusinessPostalCodeInput,
    BusinessAddressInput,
    BusinessSearchResultsPopup,
    ContactUsBusiness
  },
  computed: {
    faArrowRight() {
      return faArrowRight;
    }
  }
})
export default class extends Vue {
  postalCode: string = '';
  businessAddress: string = '';
  active: boolean = false;
  activeAddresses: boolean = false;
  address: Address | null = null;
  addresses: Array<Address> = [];
  addressComplete: boolean =
    process.env.FEATURE_FLAG_ADDRESSCOMPLETE === 'true';
  @Mutation('buildingSearch/setAddress')
  setAddress;
  @Mutation('buildingSearch/setBuildingMessage')
  setBuildingMessage;
  @Mutation('buildingSearch/initPostalCode')
  initPostalCode;
  @Mutation('buildingSearch/initAddress')
  initAddress;
  @Mutation('buildingSearch/setType')
  setType;
  successText: string | TranslateResult = '';
  successBlurb: string | TranslateResult = '';
  titleOptions = {
    goodnews: '',
    getintouch: ''
  };
  msgOptions = {
    available: '',
    progress: '',
    unavailable: '',
    interested: '',
    residential: '',
    fibrestream: ''
  };

  get faSearch() {
    return faSearch;
  }
  get searchIconActive() {
    if (this.addressComplete) {
      return this.businessAddress;
    }
    return this.postalCode && this.postalCode.length === 7;
  }
  created() {
    this.setType('business');
  }
  mounted() {
    this.initAddress();
    this.postalCode = this.$store.state.buildingSearch.postalCode;
    this.titleOptions = {
      goodnews: this.$t(
        'components:layout:business-searchform:goodnews'
      ).toString(),
      getintouch: this.$t(
        'components:layout:business-searchform:getintouch'
      ).toString()
    };
    this.msgOptions = {
      available: this.$t(
        'components:layout:business-searchform:available'
      ).toString(),
      progress: this.$t(
        'components:layout:business-searchform:progress'
      ).toString(),
      unavailable: this.$t(
        'components:layout:business-searchform:unavailable'
      ).toString(),
      interested: this.$t(
        'components:layout:business-searchform:interested'
      ).toString(),
      residential: this.$t(
        'components:layout:business-searchform:residential'
      ).toString(),
      fibrestream: this.$t(
        'components:layout:business-searchform:fibrestream'
      ).toString()
    };
  }
  @Watch('$i18n.locale')
  onLangChange() {
    this.titleOptions = {
      goodnews: this.$t(
        'components:layout:business-searchform:goodnews'
      ).toString(),
      getintouch: this.$t(
        'components:layout:business-searchform:getintouch'
      ).toString()
    };
    this.msgOptions = {
      available: this.$t(
        'components:layout:business-searchform:available'
      ).toString(),
      progress: this.$t(
        'components:layout:business-searchform:progress'
      ).toString(),
      unavailable: this.$t(
        'components:layout:business-searchform:unavailable'
      ).toString(),
      interested: this.$t(
        'components:layout:business-searchform:interested'
      ).toString(),
      residential: this.$t(
        'components:layout:business-searchform:residential'
      ).toString(),
      fibrestream: this.$t(
        'components:layout:business-searchform:fibrestream'
      ).toString()
    };
  }

  searchAddress(address) {
    if (address) {
      this.businessAddress = address.addressText;
      this.setAddress(this.businessAddress);
      this.addresses = [];
      this.setAddress(null);
      this.address = null;
      if (address.type === 'DB') {
        this.address = address;
        this.setAddress(this.address);
        this.submitAddress();
        this.openModal();
      } else {
        this.successText = this.titleOptions.getintouch;
        this.successBlurb = this.msgOptions.unavailable;
        this.openModal();
      }
    }
  }

  async submitAddress() {
    this.successText = '';
    this.successBlurb = '';
    if (this.address === null) {
      return;
    }
    this.openModal();
    if (!this.address.status) {
      this.successText = this.titleOptions.getintouch;
      this.successBlurb = this.msgOptions.unavailable;
      return;
    }
    this.setBuildingMessage(this.address.statusDescription);
    if (this.address.isOnNet) {
      this.successText = this.titleOptions.goodnews;
      if (
        this.address.isResidential &&
        this.address.status !== BuildingStatus.FibreStream
      ) {
        this.setAddress(this.address);
        this.successText = this.titleOptions.goodnews;
        this.successBlurb = this.msgOptions.residential;
        return;
      } else if (
        this.address.isResidential &&
        this.address.status === BuildingStatus.FibreStream
      ) {
        this.setAddress(this.address);
        this.successText = this.titleOptions.goodnews;
        this.successBlurb = this.msgOptions.fibrestream;
        return;
      } else {
        this.successBlurb = this.msgOptions.available;
      }
    } else if (this.address.status === BuildingStatus.Interested) {
      this.successText = this.titleOptions.getintouch;
      this.successBlurb = this.msgOptions.interested;
    } else if (this.address.status === BuildingStatus.NotOnNet) {
      this.successText = this.titleOptions.getintouch;
      this.successBlurb = this.msgOptions.unavailable;
    } else if (this.address.status === BuildingStatus.BuildInProgress) {
      this.successText = this.titleOptions.goodnews;
      this.successBlurb = this.msgOptions.progress;
    }
    this.setAddress(null);
  }

  openModal() {
    if (this.addresses.length > 1) {
      this.activeAddresses = true;
    } else {
      this.active = true;
    }
  }
  toggleModals() {
    if (this.activeAddresses) {
      this.activeAddresses = false;
      this.active = true;
    }
  }
  isGreenText() {
    return this.successText === this.titleOptions.goodnews;
  }
}
