import { render, staticRenderFns } from "./LearnMoreBoxes.vue?vue&type=template&id=159e80dd&scoped=true&"
import script from "./LearnMoreBoxes.vue?vue&type=script&lang=ts&"
export * from "./LearnMoreBoxes.vue?vue&type=script&lang=ts&"
import style0 from "./LearnMoreBoxes.vue?vue&type=style&index=0&id=159e80dd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "159e80dd",
  null
  
)

export default component.exports