



















































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import Dropdown from '~/components/widgets/Dropdown.vue';
import { ImageLink } from '~/plugins/api';

@Component({
  components: { Dropdown }
})
export default class extends Vue {
  @Prop()
  title: string;
  @Prop()
  subtitle: string;
  @Prop()
  images: Array<ImageLink>;
}
