export interface Channel {
  name: string;
  description: string;
  logoUrl: string;
  channelNumber?: number;
  isSkinny?: boolean;
  isEssentials?: boolean;
  isAlaCarte?: boolean;
  themePacks?: Array<String>;
}

export interface AlaCarteGroup {
  price: string;
  channels: Array<Channel>;
}

export interface ThemePack {
  name: string;
  price: string;
  channels: Array<Channel>;
}

export class TvPackageApi {
  name: string;
  price: number;
  themePacks: Array<ThemePack>;
  alaCarteGroups: Array<AlaCarteGroup>;
  channels: Array<Channel>;
}

export interface ImageLink {
  src: string;
  alt: string;
  description?: string;
}

export interface PhotoCollage {
  mobileImageFileUrl: string;
  desktopImageFileUrl: string;
}

export interface RateGroup {
  country: string;
  landline: number;
  mobile?: number;
}

export interface WifiVenue {
  title: string;
  imageUrl: string;
}

export enum BuildingStatus {
  Interested = 'Interested',
  OnNet = 'On-Net',
  BuildInProgress = 'Fibre Build In Progress',
  NotOnNet = 'Currently Not On-Net',
  FibreStream = 'FibreStream Active'
}

export interface AddressSearchResponse {
  Items: Address[];
}

export interface Address {
  type: 'CP' | 'DB';
  isOnNet: boolean;
  status: BuildingStatus;
  statusDescription: string;
  isResidential: boolean;
  isFibrestream: boolean;
  addressId: number;
  addressText: string;
  postalCode: string;
  city: string;
  province: string;
  streetNumber: string;
  streetNumberSuffix: string;
  streetName: string;
  streetTypeAbbr: string;
  streetDirection: string;
  hasHardwareShortage: boolean;
}

export interface Testimonial {
  name: string;
  quote: string;
}
