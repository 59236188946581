












import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { TranslateResult } from '~/node_modules/vue-i18n';

@Component({
  components: { FontAwesomeIcon }
})
export default class ArrowButton extends Vue {
  @Prop({ default: '' })
  title: string | TranslateResult;
  @Prop({ required: true })
  link: string;
  @Prop()
  icon = null;
  created() {
    this.title = this.$t('components:widgets:buttonlink:learn-more');
  }
}
