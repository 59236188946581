import { render, staticRenderFns } from "./FacilitiesBox.vue?vue&type=template&id=7f25a337&scoped=true&"
import script from "./FacilitiesBox.vue?vue&type=script&lang=ts&"
export * from "./FacilitiesBox.vue?vue&type=script&lang=ts&"
import style0 from "./FacilitiesBox.vue?vue&type=style&index=0&id=7f25a337&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f25a337",
  null
  
)

export default component.exports