













































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import buildMeta from '~/libraries/meta';

@Component
export default class LightUpWithBeanfieldLayout extends Vue {
  head() {
    return buildMeta({
      title:
        'Beanfield - 1 Gbps / $50 per month - Unlimited fibre Internet in Toronto',
      description: 'Light up with Beanfield fibre Internet.',
      canonical:
        'https://www.beanfield.com/residential/light-up-with-beanfield',
      socialImage: 'b2c-fall-campaign',
      imageType: 'image/png'
    });
  }
}
