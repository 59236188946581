


























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { RoundButton } from '~/components/widgets';
import { Prop, Watch } from 'vue-property-decorator';

import { TranslateResult } from '~/node_modules/vue-i18n';

@Component({
  components: { RoundButton }
})
export default class OrderOnlineBanner extends Vue {
  title: string | TranslateResult = '';
  subtitle: string | TranslateResult = '';
  linkTitle: string | TranslateResult = '';
  link: string = '/residential/is-beanfield-in-your-building';
  external: boolean = false;
  orderingEnabled = process.env.ONLINE_ORDERING_ENABLED === 'true';

  @Prop({ default: false })
  callSales: boolean;
  @Prop({ default: false })
  isOnNetPage: boolean;

  @Watch('$i18n.locale')
  updateTranslations() {
    this.updateText();
  }

  mounted() {
    this.updateText();
  }

  updateText() {
    const address = this['$store'].state.buildingSearch.address;
    this.title = this.$t('components:content:orderonlinebanner:title');
    this.subtitle = this.$t('components:content:orderonlinebanner:subtitle');
    this.linkTitle = this.$t(
      'rw.is-beanfield-in-your-building:on-net:button-text'
    );
    if (this.callSales || !this.orderingEnabled) {
      this.title = this.$t(
        'components:content:orderonlinebanner:contact_sales'
      );
      this.linkTitle = this.$t(
        'components:content:orderonlinebanner:give_call'
      );
      this.subtitle = this.$t(
        'components:content:orderonlinebanner:fast_internet'
      );
      this.link = '/contact-us';
    } else if (this.isOnNetPage) {
      this.subtitle = this.$t('components:content:orderonlinebanner:more_info');
      this.linkTitle = this.$t(
        'components:content:orderonlinebanner:contact_us'
      );
      this.link = '/contact-us';
    } else if (address && address.isOnNet) {
      this.link = '/residential-order';
      this.linkTitle = this.$t(
        'components:content:orderonlinebanner:order_now'
      );
      this.external = true;
    }
  }
}
