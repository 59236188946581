import Vue from "vue";

function updateElement(el) {
  if (el.tagName === "IMG") {
    el.classList.add("lazyload");
  }

  const src = el.getAttribute("src");
  const srcset = el.getAttribute("srcset");

  if (src) {
    // No default image in picture tag
    if (src.length > 0) {
      el.setAttribute("data-src", src);
      el.setAttribute(
        "src",
        "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      );
    }
  }
  if (srcset) {
    el.setAttribute("data-srcset", srcset);
    el.setAttribute(
      "srcset",
      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
    );
  }
}

Vue.directive("lazysize", {
  bind: function(el) {
    if (el.tagName === "PICTURE") {
      for (const child of el.children) {
        updateElement(child);
      }
    } else {
      updateElement(el);
    }
  }
});
