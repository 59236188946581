


























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome';
import { Prop } from 'vue-property-decorator';
import { TextLink } from '~/components/widgets';

@Component({
  components: {
    FontAwesomeIcon,
    FontAwesomeLayers,
    TextLink
  },
  computed: {
    faQuestion() {
      return faQuestion;
    },
    faComment() {
      return faComment;
    }
  }
})
export default class extends Vue {
  @Prop({ required: true })
  sectionName: string;
}
