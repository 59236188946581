

















































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMapMarkerAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Mutation } from 'vuex-class';

import {
  PostalCodeInput,
  ResiCampaignPostalCodeInput,
  ResiAddressInput
} from '~/components/inputs';
import { Modal } from '~/components/widgets';
import { Address, BuildingStatus } from '~/plugins/api';
import { Prop } from 'vue-property-decorator';

@Component({
  components: {
    ResiAddressInput,
    FontAwesomeIcon,
    PostalCodeInput,
    ResiCampaignPostalCodeInput,
    Modal
  },
  computed: {
    faMapMarkerAlt() {
      return faMapMarkerAlt;
    },
    faSearch() {
      return faSearch;
    }
  }
})
export default class SearchForm extends Vue {
  urlPrefix = '/residential/is-beanfield-in-your-building';
  postalCode: string = '';
  active: boolean = false;
  addresses: Array<Address> = [];
  address: Address | null = null;
  resAddress: string = '';
  addressComplete: boolean =
    process.env.FEATURE_FLAG_ADDRESSCOMPLETE === 'true';

  @Mutation('buildingSearch/setPostalCode')
  setPostalCode;
  @Mutation('buildingSearch/setAddress')
  setAddress;
  @Mutation('buildingSearch/setBuildingMessage')
  setBuildingMessage;
  @Mutation('buildingSearch/initPostalCode')
  initPostalCode;
  @Mutation('buildingSearch/initAddress')
  initAddress;
  @Mutation('buildingSearch/setType')
  setType;

  @Prop()
  placeholder: string;

  @Prop()
  is_transparent: {
    type: boolean;
    default: false;
  };

  get searchIconActive() {
    return this.resAddress || (this.postalCode && this.postalCode.length === 7);
  }

  created() {
    this.setType('residential');
    this.initPostalCode();
  }

  mounted() {
    this.initAddress();
    this.resAddress = this.$store.state.buildingSearch.address;
    this.postalCode = this.$store.state.buildingSearch.postalCode;
  }

  searchAddress(address) {
    this['$ga'].event({
      eventCategory: 'Site Search',
      eventAction: this.postalCode,
      eventLabel: this['$route'].path
    });
    if (address) {
      window.scrollTo(0, 0);
      if (address.type === 'DB') {
        this.address = address;
        this.setAddress(this.address);
        this.submitAddress();
      } else {
        this.setAddress(null);
        if (
          this.$router.currentRoute.path !== `${this.urlPrefix}/not-in-system`
        )
          this.$router.push(`${this.urlPrefix}/not-in-system`);
      }
    }
  }

  async submitAddress() {
    this['$ga'].event({
      eventCategory: 'Site Search',
      eventAction: this.resAddress,
      eventLabel: this['$route'].path
    });
    if (this.address === null) {
      return;
    }
    if (this.address.type !== 'DB') {
      this.setAddress(null);
      if (this.$router.currentRoute.path !== `${this.urlPrefix}/not-on-net`)
        await this.$router.push(`${this.urlPrefix}/not-on-net`);
    }
    this.setBuildingMessage(this.address.statusDescription);
    if (this.address.isOnNet) {
      if (this.address.isResidential && !this.address.isFibrestream) {
        this.setAddress(this.address);
        if (this.$router.currentRoute.path !== `${this.urlPrefix}/on-net`) {
          await this.$router.push({
            path: `${this.urlPrefix}/on-net`,
            query: {
              postal_code: this.address.postalCode
            }
          });
          return;
        } else {
          await this.$router.push({
            query: {
              postal_code: this.address.postalCode,
              _timestamp: new Date().getTime().toString()
            }
          });
          return;
        }
      } else if (this.address.isResidential && this.address.isFibrestream) {
        this.setAddress(this.address);
        if (
          this.$router.currentRoute.path !==
          `${this.urlPrefix}/fibrestream-active`
        )
          await this.$router.push(`${this.urlPrefix}/fibrestream-active`);
        return;
      } else {
        this.setAddress(null);
        if (this.$router.currentRoute.path !== `${this.urlPrefix}/on-net-order`)
          await this.$router.push(`${this.urlPrefix}/on-net-order`);
      }
    } else if (this.address.status === BuildingStatus.Interested) {
      this.setAddress(null);
      if (this.$router.currentRoute.path !== `${this.urlPrefix}/interested`)
        await this.$router.push(`${this.urlPrefix}/interested`);
    } else if (this.address.status === BuildingStatus.NotOnNet) {
      this.setAddress(null);
      if (this.$router.currentRoute.path !== `${this.urlPrefix}/not-on-net`)
        await this.$router.push(`${this.urlPrefix}/not-on-net`);
    } else if (this.address.status === BuildingStatus.BuildInProgress) {
      this.setAddress(null);
      if (this.$router.currentRoute.path !== `${this.urlPrefix}/in-progress`)
        await this.$router.push(`${this.urlPrefix}/in-progress`);
    }
    this.setAddress(null);
  }
}
