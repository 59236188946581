










import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { TranslateResult } from '~/node_modules/vue-i18n';

@Component({})
export default class VerticalPriceBox extends Vue {
  @Prop({ required: true })
  price: string;
  @Prop({ required: true })
  frequency: string | TranslateResult;
}
