




















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class Banner extends Vue {
  @Prop()
  title;
  @Prop()
  subtitle;
}
