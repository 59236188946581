
















import Vue from 'vue';
import Component from 'nuxt-class-component';

import { Navbar, Footer, SocialBar, SiteNav } from '~/components/layout';

@Component({
  components: { Navbar, Footer, SocialBar, SiteNav }
})
export default class extends Vue {}
