










import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class RoundButton extends Vue {
  @Prop({ required: true })
  link: string;
  @Prop({ default: false })
  external: boolean;
  @Prop({ default: '' })
  extra_classes: string;
}
