





























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  components: { FontAwesomeIcon },
  computed: {
    faPlay() {
      return faPlay;
    }
  }
})
export default class extends Vue {
  @Prop()
  link: string;
  @Prop()
  title: string;
  @Prop({ default: false })
  link_channels: boolean;
}
