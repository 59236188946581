













import Vue from 'vue';
import Component from 'nuxt-class-component';

@Component({})
export default class SmallInternetFeatures extends Vue {}
