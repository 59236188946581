
















import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'nuxt-class-component';

@Component({})
export default class Dropdown extends Vue {
  @Prop({ default: false })
  active: boolean;
}
