


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component({})
export default class extends Vue {
  @Prop({ default: false })
  active: boolean;
  isActive: boolean = this.active || false;

  @Watch('active')
  onActiveChanged() {
    this.isActive = this.active;
  }
  @Watch('isActive')
  onIsActiveChanged() {
    this.scrollFix();
  }

  close() {
    this.isActive = false;
    this.$emit('update:active', false);
  }
  scrollFix() {
    document.documentElement.classList.toggle('is-clipped', this.isActive);
  }
}
