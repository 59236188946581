import Vue from "vue";
import Notifications from "vue-notification/dist/ssr.js";

Vue.use(Notifications);

export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    Vue.prototype.$notify({
      group: "main",
      clear: true
    });
    next();
  });
};
