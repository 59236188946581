






















































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { SectionHeader } from '~/components/widgets';
import {
  localeChanged,
  localize,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import { Watch } from 'vue-property-decorator';

@Component({
  components: { SectionHeader, ValidationObserver, ValidationProvider }
})
export default class BecomeAPartnerForm extends Vue {
  firstName: string = '';
  lastName: string = '';
  companyName: string = '';
  email: string = '';
  telephone: string = '';
  message: string = '';
  subscribe: boolean = false;
  submitSuccessful: boolean | null = null;
  captchaError: boolean = false;
  $refs!: {
    formRef;
  };
  @Watch('$i18n.locale')
  translate_error_msg() {
    localize(this.$i18n.locale);
    localize({
      en: {
        names: {
          'First Name': 'First Name',
          Email: 'Email'
        }
      },
      fr: {
        names: {
          'First Name': 'Prénom',
          Email: 'Courriel'
        }
      }
    });
    localeChanged();
  }

  async mounted() {
    await this.$recaptcha.init();
  }
  async submitForm() {
    this.captchaError = false;
    try {
      const token = await this.$recaptcha.getResponse();
      this['$axios']
        .post('/business/partner-contact-form', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          companyName: this.companyName,
          telephone: this.telephone
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          message: this.message,
          subscribe: this.subscribe,
          language: this.$i18n.locale,
          token: token
        })
        .then(res => {
          if (res.data) {
            if (res.data.successful) {
              this.firstName = this.lastName = this.companyName = this.email =
                '';
              this.telephone = this.message = '';
              this.subscribe = false;
              this.submitSuccessful = true;
              let action = 'Sent Become a Partner';

              this['$ga'].event({
                eventCategory: 'Business Partner Contact Form',
                eventAction: action,
                eventLabel: this['$route'].path
              });

              this.$emit('formsubmitted', '');
            } else if (res.data.error) {
              this.$refs.formRef.setErrors(res.data.error_dict);
            } else {
              this.submitSuccessful = false;
            }
          } else {
            this.submitSuccessful = false;
          }
        })
        .catch(() => {
          this.submitSuccessful = false;
        });
      // reset recaptcha
      await this.$recaptcha.reset();
    } catch (error) {
      this.captchaError = true;
    }
  }
}
