

















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class IconHolder extends Vue {
  @Prop({ required: true })
  icon: string;
  @Prop({ default: '' })
  text: string;
}
