
































import Vue from 'vue';
import Component from 'nuxt-class-component';

import {
  Navbar,
  Footer,
  SocialBar,
  SiteNav,
  SearchBar
} from '~/components/layout';

@Component({
  components: { Navbar, Footer, SocialBar, SiteNav, SearchBar }
})
export default class extends Vue {}
