import Cookies from 'js-cookie';

export const state = () => ({
  lang: ''
});

export const mutations = {
  initLanguage(state) {
    let searchParams = new URLSearchParams(document.location.search);
    state.lang = Cookies.get('_LOCALE_');
    if (searchParams.has('_LOCALE_') || typeof state.lang === 'undefined') {
      let locale = (
        searchParams.get('_LOCALE_') ||
        state.lang ||
        'en'
      ).toLowerCase();
      if (!['en', 'fr'].includes(locale)) {
        locale = 'en';
      }
      state.lang = locale;
      Cookies.set('_LOCALE_', locale, {
        expires: 3650, // 10 years
        path: '/',
        domain: getCookieDomain()
      });
    }
  },
  setLanguage(state, language) {
    state.lang = language;
    Cookies.set('_LOCALE_', language, {
      expires: 3650, // 10 years
      path: '/',
      domain: getCookieDomain()
    });
  }
};

function getCookieDomain() {
  /* Returns current cookie domain
   *
   * This should be the subdomain so that it works
   * across apps, e.g. beanfield.com instead of
   * boss.beanfield.com or www.beanfield.com
   */

  let domain = document.domain
    .split('.')
    .slice(1)
    .join('.');
  if (
    domain == 'com' ||
    domain == 'localdomain' ||
    document.domain == 'staging.beanfield.com'
  ) {
    // No subdomain found so revert to full domain
    domain = document.domain;
  }
  return domain;
}
