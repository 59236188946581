









import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop, Inject } from 'vue-property-decorator';
import { Carousel } from '~/components/widgets';

@Component({
  components: {
    Carousel
  }
})
export default class Slide extends Vue {
  @Inject()
  carousel;
  @Prop({ default: 1 })
  slideNumber: number;
  position(index) {
    if (index === this.carousel.getCurrent()) return 0;
    if (index === (this.carousel.getCurrent() + 1) % this.carousel.rowCount) {
      if (this.carousel.rowCount == 2 && index == 0) return -1;
      return 1;
    }
    return -1;
  }
}
