









































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SectionHeader from '~/components/widgets/SectionHeader.vue';
import { Mutation } from 'vuex-class';
@Component({
  components: {
    SectionHeader
  }
})
export default class BusinessServices extends Vue {
  @Prop({
    validator: value => {
      return (
        [
          'home',
          'business-internet',
          'business-phone',
          'private-networks',
          'colocation',
          'cloud-connect'
        ].indexOf(value) !== -1
      );
    }
  })
  currentPage: string;

  @Prop({
    default: false
  })
  showQuoteButton: boolean;
  @Mutation('businessForm/setSideFormState')
  setSideFormState;
  setSideBarState() {
    this.setSideFormState(true);
  }
}
