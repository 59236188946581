
























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class TitleBanner extends Vue {
  @Prop()
  title: string;
  @Prop({ default: false })
  useNewLayout: boolean;
}
