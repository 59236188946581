














































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Mutation } from 'vuex-class';

@Component({
  components: {
    FontAwesomeIcon
  }
})
export default class extends Vue {
  showTopNavDropdown: boolean = false;
  isBusiness: boolean = false;
  isResidential: boolean = false;
  @Mutation('lang/initLanguage')
  initLanguage;
  @Mutation('lang/setLanguage')
  setLanguage;

  get faUser() {
    return faUser;
  }

  @Watch('$route')
  checkServices(to) {
    this.checkServicesRouteActive(to);
    this.showTopNavDropdown = false;
  }

  mounted() {
    this.initLanguage();
    this.$i18n.locale = this.$store.state.lang.lang;
    this.checkServicesRouteActive(this.$route);
  }
  langActive(l) {
    if (this.$i18n.locale === l) {
      return true;
    }
  }
  lang(l) {
    this.$i18n.locale = l;
    this.setLanguage(l);
    this.showTopNavDropdown = false;
  }

  // Main path needs custom check to show as active as supports shares same base url
  checkServicesRouteActive(route) {
    const path: string = route.path;
    this.isResidential = path.indexOf('/residential') !== -1;
    this.isBusiness = path.indexOf('/business') !== -1;
    this.showTopNavDropdown = false;
  }
}
