































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

import { ArrowButton } from '~/components/widgets';

@Component({
  components: { ArrowButton }
})
export default class TvBox extends Vue {
  @Prop()
  packageTitle: string;
  @Prop()
  price: string;
  @Prop()
  channelCount: number;
  @Prop()
  packageLink: string;
  @Prop()
  linkText: string;
  @Prop()
  title: string;
}
