




















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class PartnersTitleBox extends Vue {
  @Prop()
  headerTitle: string;

  @Prop()
  headerSubTitle: string;
}
