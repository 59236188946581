








import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { TranslateResult } from '~/node_modules/vue-i18n';
@Component({})
export default class ImageBlurb extends Vue {
  @Prop({ default: false })
  text: string | TranslateResult;
}
