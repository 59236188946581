




















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { TranslateResult } from '~/node_modules/vue-i18n';

export class Crumb {
  title: string | TranslateResult;
  link: string;
  constructor(title: string | TranslateResult, link: string) {
    this.title = title;
    this.link = link;
  }
}

@Component({})
export default class extends Vue {
  @Prop()
  crumbs: Array<Crumb>;
  @Prop({ default: true })
  includeHr: boolean;
}
