











































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import snakeCase from 'lodash/snakeCase';
import VueScrollTo from 'vue-scrollto';

@Component({
  components: { FontAwesomeIcon },
  computed: {
    faAngleDown: function() {
      return faAngleDown;
    },
    faAngleUp: function() {
      return faAngleUp;
    }
  }
})
export default class ShowHide extends Vue {
  @Prop()
  title: string;
  @Prop()
  show: boolean;

  showContent: boolean = false;
  anchor: string;

  created() {
    if (this.title) {
      this.anchor = snakeCase(this.title);
    }
  }

  mounted() {
    if (this.show) {
      this.showContent = this.show;
    }
    const currentHash = this['$route'].hash;
    if (this.anchor && currentHash.indexOf(this.anchor) !== -1) {
      this.showContent = true;
      // Make sure page is loaded and scroll to anchor
      this.$nextTick(function() {
        const q = document.querySelector(currentHash);
        // Avoid typescript possibly null error
        if (q !== null) {
          VueScrollTo.scrollTo(q);
        }
      });
    }
  }

  toggle() {
    this.showContent = !this.showContent;
    if (this.anchor) {
      let currentPath = this['$route'].path;
      const hash = this.showContent ? this.anchor : '';
      this['$router'].replace({ path: currentPath, hash: hash });
    }
  }
}
