



























import Vue from 'vue';
import Component from 'nuxt-class-component';

import {
  Navbar,
  BusinessFooter,
  SiteNav,
  BusinessNavbar,
  BusinessSiteNav
} from '~/components/layout';

@Component({
  components: {
    Navbar,
    BusinessFooter,
    SiteNav,
    BusinessNavbar,
    BusinessSiteNav
  }
})
export default class extends Vue {}
