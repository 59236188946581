




import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { TranslateResult } from '~/node_modules/vue-i18n';

@Component({})
export default class extends Vue {
  @Prop()
  headerTitle: string | TranslateResult;
  @Prop({ default: 'large' })
  headerSize: 'large' | 'small';

  sectionHeaderClass() {
    return {
      'section-title': true,
      'section-title-small': this.headerSize === 'small',
      'section-title-large': this.headerSize === 'large'
    };
  }
}
