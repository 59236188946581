










import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class PostalCodeInput extends Vue {
  @Prop()
  value: string;

  postalCodeRegex: RegExp = /[A-Z0-9]$/i;

  $refs: {
    input: HTMLFormElement;
  };

  updateValue(value) {
    let formattedValue = this.formatPostalCode(value);
    if (formattedValue !== value) {
      this.$refs.input.value = formattedValue;
    }

    this.$emit('input', formattedValue);
  }
  formatPostalCode(value): string {
    let formattedValue = value.trim().toUpperCase();
    const length = formattedValue.length;
    if (!this.postalCodeRegex.test(formattedValue)) {
      return formattedValue.slice(0, length - 1);
    }
    if (length > 3 && formattedValue.indexOf(' ') === -1) {
      formattedValue =
        formattedValue.slice(0, 3) + ' ' + formattedValue.slice(3, 6);
    }

    return formattedValue;
  }
}
