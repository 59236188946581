





















































import Vue from 'vue';
import Component from 'nuxt-class-component';

import ServiceBox from '~/components/widgets/ServiceBox.vue';
import { ArrowButton } from '~/components/widgets';

@Component({
  components: {
    ServiceBox,
    ArrowButton
  }
})
export default class extends Vue {}
