














import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class FloodLight extends Vue {
  comments: true;
  @Prop()
  cat: String;
  @Prop()
  source: Number;
  @Prop()
  type: String;
  order: Number;
  created() {
    let random_number = Math.random();
    this.order = random_number * 10000000000000;
  }
}
