




































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BusinessSearchBar from '~/components/layout/BusinessSearchBar.vue';

@Component({
  components: {
    FontAwesomeIcon,
    BusinessSearchBar
  }
})
export default class extends Vue {}
