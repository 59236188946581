

































































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { QuestionItem, SurveyData } from '~/types/accessibility';
import { isEmpty } from 'lodash';

@Component({
  components: { ValidationProvider, ValidationObserver },
  methods: { isEmpty }
})
export default class AccessibilityForm extends Vue {
  name = '';
  email = '';
  submitted = false;
  survey: QuestionItem[] = [];

  scrollToTop(): void {
    if (window) {
      window.scrollTo({ top: 0 });
    }
  }

  mounted(): void {
    this.survey = [
      {
        question_fr: this.$t('accessibility:question-1', 'fr'),
        question_en: this.$t('accessibility:question-1', 'en'),
        question_key: 'accessibility:question-1',
        answer: ''
      },
      {
        question_fr: this.$t('accessibility:question-2', 'fr'),
        question_en: this.$t('accessibility:question-2', 'en'),
        question_key: 'accessibility:question-2',
        answer: ''
      },
      {
        question_fr: this.$t('accessibility:question-3', 'fr'),
        question_en: this.$t('accessibility:question-3', 'en'),
        question_key: 'accessibility:question-3',
        answer: ''
      },
      {
        question_fr: this.$t('accessibility:question-4', 'fr'),
        question_en: this.$t('accessibility:question-4', 'en'),
        question_key: 'accessibility:question-4',
        answer: ''
      }
    ];
  }
  async submitResponse(): Promise<void> {
    const submitData: SurveyData = {
      survey: this.survey
    };
    let msg = '';
    if (this.name && this.email) {
      submitData['user'] = {
        name: this.name,
        email: this.email
      };
    }
    const { data } = await this.$axios.post(
      '/accessibility/submit',
      submitData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    if (data.error) {
      msg = data.msg as string;
    }
    if (data.success) {
      this.submitted = true;
      msg = this.$t('accessibility:results:success-msg') as string;
      this.scrollToTop();
    }
    Snackbar.open({
      message: msg,
      type: 'is-success',
      duration: 5000,
      position: 'is-top'
    });
  }
  submitNewResponse(): void {
    // clear out fields
    for (const entry of this.survey) {
      entry.answer = '';
    }
    this.name = '';
    this.email = '';
    this.submitted = false;
  }
  get anyFieldAnswered() {
    return isEmpty(this.survey.filter(entry => entry.answer !== ''));
  }
}
