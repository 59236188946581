






































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class SocialFeed extends Vue {
  @Prop()
  socialFeeds: any[];

  internalSocialFeeds: any[] = [];

  mounted() {
    this.internalSocialFeeds = this.socialFeeds;

    if (!window['twttr']) {
      window['twttr'] = ((d, s, id) => {
        let js,
          fjs = d.getElementsByTagName(s)[0],
          t = window['twttr'] || {};
        if (d.getElementById(id)) return t;
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://platform.twitter.com/widgets.js';
        if (fjs.parentNode !== null) {
          fjs.parentNode.insertBefore(js, fjs);
          js.addEventListener('load', this.populateTweetEmbed);
          t._e = [];
          t.ready = function(f) {
            t._e.push(f);
          };

          return t;
        }
      })(document, 'script', 'twitter-wjs');
    } else {
      this.populateTweetEmbed();
    }

    window['fbAsyncInit'] = function() {
      window['FB'].init({
        appId: '952909024914090',
        autoLogAppEvents: true,
        xfbml: true,
        status: true,
        cookie: true,
        version: 'v3.2'
      });
    };
    (function(d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src =
        'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2&appId=952909024914090&autoLogAppEvents=1';
      js.async = true;
      if (fjs.parentNode !== null) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, 'script', 'facebook-jssdk');

    this.populateFBEmbed();

    if (!window['instgrm']) {
      window['IG'] = ((d, s, id) => {
        let js,
          fjs = d.getElementsByTagName(s)[0],
          t = window['IG'] || {};
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://www.instagram.com/embed.js';
        if (fjs.parentNode !== null) {
          fjs.parentNode.insertBefore(js, fjs);
          js.addEventListener('load', this.setInstagramFeed);
          return t;
        }
      })(document, 'script', 'instagram-jssdk');
    } else {
      /* eslint-disable no-console */
      this.setInstagramFeed();
      /* eslint-enable no-console */
    }
  }
  populateFBEmbed() {
    /* eslint-disable no-console */
    if (
      typeof window['FB'] !== 'undefined' &&
      typeof window['FB'].XFBML !== 'undefined'
    ) {
      this.$nextTick(function() {
        window['FB'].XFBML.parse();
      });
    }
    /* eslint-enable no-console */
  }
  populateTweetEmbed() {
    /* eslint-disable no-console */
    let socialFeeds = this.internalSocialFeeds;
    this.$nextTick(function() {
      window['twttr'].ready(() => {
        for (let i = 0; i < socialFeeds.length; i++) {
          if (socialFeeds[i].type === 'twitter_feed') {
            window['twttr'].widgets.createTweet(
              socialFeeds[i].data,
              document.getElementById(socialFeeds[i].data),
              {
                width: 400,
                conversation: 'none',
                align: 'center'
              }
            );
          }
        }
      });
    });
  }
  /* eslint-enable no-console */
  setInstagramFeed() {
    let socialFeeds = this.internalSocialFeeds;
    let promises: any[] = [];
    for (let i = 0; i < socialFeeds.length; i++) {
      if (socialFeeds[i].type === 'instagram_feed') {
        promises.push(
          this.$axios.get(
            'https://api.instagram.com/oembed/?url=' +
              socialFeeds[i].data +
              '?omitscript=true'
          )
        );
      }
    }
    Promise.all(promises)
      .then(results => {
        if (results.length > 0) {
          let j = 0;
          for (let i = 0; i < socialFeeds.length; i++) {
            if (socialFeeds[i].type === 'instagram_feed') {
              socialFeeds[i].data = results[j].data.html;
              j = j + 1;
            }
          }
          this.internalSocialFeeds = socialFeeds;
          this.$nextTick(function() {
            window['instgrm'].Embeds.process();
          });
        }
      })
      .catch(() => {});
  }
  beforeDestroy() {
    if (typeof window['fbAsyncInit'] !== 'undefined') {
      delete window['fbAsyncInit'];
      delete window['FB'];
      let fb_sdk = document.getElementById('facebook-jssdk');
      if (fb_sdk !== undefined && fb_sdk != null) {
        fb_sdk.remove();
      }
    }
  }
}
