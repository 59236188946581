





















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

@Component({
  components: { FontAwesomeIcon }
})
export default class extends Vue {
  @Prop()
  link: string;
  @Prop()
  text: string;
  @Prop()
  icon: any;
  @Prop()
  flip: string;
  @Prop()
  disabled: boolean;
}
