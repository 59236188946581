import Cookies from 'js-cookie';
import { Address } from '~plugins/api';

export const state = () => ({
  postalCode: '',
  address: null,
  buildingMessage: null,
  type: ''
});

export const mutations = {
  setType(state, type) {
    Cookies.set('type', type);
    state.type = Cookies.get('type', '');
  },
  initPostalCode(state) {
    // clear out the postalCode state to ensure there is no copy over
    // when switching between the two sites
    state.postalCode = '';
    if (state.type === 'business') {
      state.postalCode = Cookies.get('business-postal-code', '');
    } else {
      state.postalCode = Cookies.get('postal-code', '');
    }
  },
  setPostalCode(state, postalCode) {
    state.postalCode = postalCode;
    if (state.type === 'business') {
      Cookies.set('business-postal-code', postalCode);
    } else {
      Cookies.set('postal-code', postalCode);
    }
  },
  initAddress(state) {
    state.address = Cookies.getJSON('address');
  },
  setAddress(state, address) {
    state.address = address;
    // Online ordering requires address and address_id stored in separate vars
    if (address) {
      localStorage.setItem('address', address.addressText);
      localStorage.setItem('address_id', address.addressId);

      // TP-5038
      checkAddressHasHardwareShortage(address);
    } else {
      localStorage.removeItem('address');
      localStorage.removeItem('address_id');
    }

    Cookies.set('address', address);
  },
  setBuildingMessage(state, message) {
    state.buildingMessage = message;
  }
};

// TP-5038: set a variable in the localstorage if the address has a
// hardware shortage
function checkAddressHasHardwareShortage(address: Address): void {
  if (address.hasHardwareShortage) {
    localStorage.setItem('hardware_shortage', 'yes');
  } else {
    if (localStorage.getItem('hardware_shortage')) {
      localStorage.removeItem('hardware_shortage');
    }
  }
}
