import debounce from 'lodash/debounce';

export function Debounce(duration: number = 500): Function {
  // https://github.com/bvaughn/debounce-decorator
  return function decorator(
    target: any,
    key: string,
    descriptor: PropertyDescriptor
  ) {
    return {
      configurable: true,
      enumerable: descriptor.enumerable,
      value: debounce(descriptor.value, duration)
    };
  };
}
