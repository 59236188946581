























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import SectionHeader from '~/components/widgets/SectionHeader.vue';
import { FacilitiesBox } from '~/components/content/';
@Component({
  components: {
    SectionHeader,
    FacilitiesBox
  }
})
export default class BusinessFacilities extends Vue {
  @Prop({
    validator: value => {
      return (
        [
          '250-front-st',
          '23-fraser-ave',
          '151-front-st',
          '905-king-st',
          '3445-park-ave'
        ].indexOf(value) !== -1
      );
    }
  })
  currentPage: string;
}
