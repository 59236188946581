














































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import buildMeta from '~/libraries/meta';

@Component
export default class HybridWorksLayout extends Vue {
  head() {
    return buildMeta({
      title: 'Hybrid Workplace | Fibre Internet | Beanfield | Bandwidth',
      description: 'Make Hybrid Work With Beanfield.',
      canonical: 'https://www.beanfield.com/business/hybrid-works',
      socialImage: 'b2b-fall-campaign',
      imageType: 'image/png'
    });
  }
}
