
















import Vue from 'vue';
import Component from 'nuxt-class-component';

import { BusinessSearchForm } from '~/components/forms';

@Component({
  components: { BusinessSearchForm },
  computed: {
    address() {
      return this.$store.state.buildingSearch.address;
    }
  }
})
export default class extends Vue {}
