
































import Vue from 'vue';
import Component from 'nuxt-class-component';

import { SearchForm } from '~/components/forms';
import { Mutation } from 'vuex-class';

@Component({
  components: { SearchForm }
})
export default class extends Vue {
  urlPrefix = '/residential/is-beanfield-in-your-building';
  @Mutation('buildingSearch/setAddress')
  setAddress;
  addressComplete: boolean =
    process.env.FEATURE_FLAG_ADDRESSCOMPLETE === 'true';

  get address() {
    return this.$store.state.buildingSearch.address;
  }

  async clear() {
    this.setAddress(null);
    if (
      this['$route'].path.includes(`${this.urlPrefix}/in-progress`) ||
      this['$route'].path.includes(`${this.urlPrefix}/interested`) ||
      this['$route'].path.includes(`${this.urlPrefix}/not-in-system`) ||
      this['$route'].path.includes(`${this.urlPrefix}/not-on-net`) ||
      this['$route'].path.includes(`${this.urlPrefix}/on-net`) ||
      this['$route'].path.includes(`${this.urlPrefix}/on-net-order`)
    ) {
      await this.$router.push('/residential');
    }
  }
}
