










































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { SectionHeader, HostedPhoneModal } from '~/components/widgets';
import VueSlickCarousel from 'vue-slick-carousel';

@Component({
  components: {
    HostedPhoneModal,
    SectionHeader
  }
})
export default class PolyPhones extends Vue {
  slickOptions = {
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    arrows: false,
    centerPadding: '60px',
    slidesToScroll: 1,
    swipe: false
  };
  slickOptionsMobile = {
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    centerPadding: '60px',
    slidesToScroll: 1
  };
  $refs: {
    carousel: VueSlickCarousel;
    carouselMobile: VueSlickCarousel;
  };
  showNext() {
    this.$refs.carousel.next();
  }
  showPrevious() {
    this.$refs.carousel.prev();
  }
  active: boolean = false;
  openModal() {
    this.active = true;
  }
  active150: boolean = false;
  active250: boolean = false;
  active301: boolean = false;
  active350: boolean = false;
  active53p: boolean = false;
  active401: boolean = false;
  active450: boolean = false;
  active501: boolean = false;
  active601: boolean = false;
  active8800: boolean = false;
  active930w: boolean = false;
  openModal150() {
    this.active150 = true;
  }
  openModal250() {
    this.active250 = true;
  }
  openModal301() {
    this.active301 = true;
  }
  openModal350() {
    this.active350 = true;
  }
  openModal53p() {
    this.active53p = true;
  }
  openModal401() {
    this.active401 = true;
  }
  openModal450() {
    this.active450 = true;
  }
  openModal501() {
    this.active501 = true;
  }
  openModal601() {
    this.active601 = true;
  }
  openModal8800() {
    this.active8800 = true;
  }
  openModal930w() {
    this.active930w = true;
  }
}
