









































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Mutation } from 'vuex-class';

import { Navbar, SiteNav } from '~/components/layout';

@Component({
  components: {
    Navbar,
    SiteNav
  }
})
export default class extends Vue {
  @Mutation('lang/initLanguage')
  initLanguage;
  @Mutation('lang/setLanguage')
  setLanguage;

  mounted() {
    this.initLanguage();
    this.$i18n.locale = this.$store.state.lang.lang;
  }
  lang(l) {
    this.$i18n.locale = l;
    this.initLanguage(l);
  }
}
