



















































































































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionHeader } from '~/components/widgets';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Debounce } from '~/libraries/requests.ts';
import { AxiosResponse } from 'axios';
import { AddressSearchResponse } from '~/types/forms';
import { Mutation } from 'vuex-class';
import getUTMData from '~/libraries/utm';

@Component({
  components: { SectionHeader, ValidationObserver, ValidationProvider }
})
export default class BusinessContactForm extends Vue {
  @Prop({ default: '' })
  subject: string;
  isFetching: boolean = false;
  dataAddress: any = [];
  firstName: string = '';
  lastName: string = '';
  companyName: string = '';
  serviceAddress: string = '';
  email: string = '';
  telephone: string = '';
  helpOptions = [];
  message: string = '';
  subscribe: boolean = false;
  apiKey: string = '';
  submitSuccessful: boolean | null = null;
  captchaError: boolean = false;
  $refs: {
    formRef;
  };

  @Mutation('businessForm/getCanadaPostApiKey')
  getCanadaPostApiKey;
  async mounted() {
    this.getCanadaPostApiKey();
    this.apiKey = this.$store.state.businessForm.apiKey;
    await this.$recaptcha.init();
  }

  @Debounce(500)
  async searchAddress(): Promise<void> {
    const response: AxiosResponse<
      AddressSearchResponse
    > = await this.$axios.get(
      'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws',
      {
        params: {
          Key: this.apiKey,
          SearchTerm: this.serviceAddress,
          Country: 'CAN',
          MaxResults: 7
        }
      }
    );
    this.dataAddress = [];
    let serviceAddress = response.data.Items;
    if (serviceAddress.length >= 1 && serviceAddress[0].Error === undefined) {
      serviceAddress.forEach(element =>
        this.dataAddress.push(element.Text + ' ' + element.Description)
      );
    }
  }

  async submitForm() {
    this.captchaError = false;
    try {
      const token = await this.$recaptcha.getResponse();
      this['$axios']
        .post('/business/campaign-contact-form', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          serviceAddress: this.serviceAddress,
          companyName: this.companyName,
          telephone: this.telephone
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          helpOptions: this.helpOptions.join(', '),
          message: this.message,
          subscribe: this.subscribe,
          language: this.$i18n.locale,
          subject: this.subject,
          token: token,
          ...getUTMData()
        })
        .then(res => {
          if (res.data) {
            if (res.data.successful) {
              this.firstName = this.lastName = this.companyName = this.email =
                '';
              this.telephone = this.serviceAddress = this.message = '';
              this.helpOptions = [];
              this.subscribe = false;
              this.submitSuccessful = true;
              let action = 'Sent Business Form';

              this['$ga'].event({
                eventCategory: 'b2b_form_submit',
                eventAction: action,
                eventLabel: this['$route'].path
              });

              this.$emit('formsubmitted');
            } else if (res.data.error) {
              this.$refs.formRef.setErrors(res.data.error_dict);
            } else {
              this.submitSuccessful = false;
            }
          } else {
            this.submitSuccessful = false;
          }
        })
        .catch(() => {
          this.submitSuccessful = false;
        });
      // reset recaptcha
      await this.$recaptcha.reset();
    } catch (error) {
      this.captchaError = true;
    }
  }
}
