import { render, staticRenderFns } from "./BecomeAPartnerForm.vue?vue&type=template&id=652ded69&scoped=true&"
import script from "./BecomeAPartnerForm.vue?vue&type=script&lang=ts&"
export * from "./BecomeAPartnerForm.vue?vue&type=script&lang=ts&"
import style0 from "./BecomeAPartnerForm.vue?vue&type=style&index=0&id=652ded69&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652ded69",
  null
  
)

export default component.exports