
























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class DataCentreBox extends Vue {
  @Prop()
  city: string;
  @Prop()
  address: string;
  @Prop()
  useLearnMore: boolean;
  @Prop()
  link: string;
}
