





















import { Component, Prop, Vue } from 'vue-property-decorator';
import RoundButton from '~/components/widgets/RoundButton.vue';

@Component({
  components: { RoundButton }
})
export default class BannerOverlay extends Vue {
  @Prop()
  title: string;
  @Prop()
  subtitle: string;
  @Prop()
  useButton: boolean;
  @Prop()
  buttonText: string;
  @Prop()
  buttonLink: string;
  @Prop({ default: false })
  external: boolean;
  @Prop()
  isException: boolean;
  @Prop()
  message: string;
}
