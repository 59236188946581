















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { ArrowButton } from '~/components/widgets';

@Component({
  components: { ArrowButton }
})
export default class extends Vue {
  @Prop()
  boxTitle: string;
  @Prop()
  boxImage: string;
  @Prop({ required: true })
  boxLink: string;
}
