









































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Navbar, SiteNav } from '~/components/layout';
import { localeChanged } from 'vee-validate';
import { localize } from 'vee-validate';
import { Mutation } from 'vuex-class';

@Component({
  components: {
    Navbar,
    SiteNav
  }
})
export default class extends Vue {
  @Mutation('lang/setLanguage')
  setLanguage;

  mounted() {
    let language = this.$route.query.locale;
    if (language === 'fr_CA') {
      this.setLanguage('fr');
      this.$i18n.locale = 'fr';
      localize('fr');
    } else {
      this.setLanguage('en');
      this.$i18n.locale = 'en';
      localize('en');
    }
    localeChanged(); // notify vee-validate of localization changes
  }
  lang(l) {
    this.$i18n.locale = l;
    this.setLanguage(l);
    localize(l);
    localeChanged(); // notify vee-validate of localization changes
  }
}
