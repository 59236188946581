




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Crumb } from '~components/widgets/Breadcrumbs.vue';
import { BusinessBreadcrumbs } from '~/components';
@Component({
  components: {
    BusinessBreadcrumbs,
    FontAwesomeIcon
  },
  computed: {
    faArrowRight() {
      return faArrowRight;
    }
  }
})
export default class ContactBox extends Vue {
  @Prop()
  imgSrc1: string;
  @Prop()
  imgAlt1: string;
  @Prop()
  imgSrc2: string;
  @Prop()
  imgAlt2: string;
  @Prop()
  imgSrc3: string;
  @Prop()
  imgAlt3: string;
  @Prop()
  imgSrc4: string;
  @Prop()
  imgAlt4: string;
  @Prop()
  crumbs: Array<Crumb>;
}
