













































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import buildMeta from '~/libraries/meta';

@Component
export default class GoSteadyWithBeanfieldLayout extends Vue {
  head() {
    return buildMeta({
      title: 'Steady Connection | Fibre Internet | Beanfield | Speed',
      description:
        'Enjoy a fast and steady connection with Beanfield fibre Internet.',
      canonical:
        'https://www.beanfield.com/residential/go-steady-with-beanfield',
      socialImage: 'b2c-summer-campaign',
      imageType: 'image/png'
    });
  }
}
