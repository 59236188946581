export const state = () => ({
  open: false,
  isOpen: false,
  apiKey: ''
});

export const mutations = {
  setSideFormState(state, open) {
    state.open = open;
    state.isOpen = true;
  },
  getCanadaPostApiKey(state) {
    if (process.env.NODE_ENV === 'production') {
      state.apiKey = process.env.PRODUCTION_API_KEY;
    } else {
      state.apiKey = process.env.API_KEY;
    }
  }
};
