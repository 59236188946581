export default function buildMeta(options) {
  const defaults = {
    'og:title': options.title,
    'og:description': options.description,
    'og:url': options.canonical,
    'og:image:type': options.imageType,
    'og:image:width': '1200',
    'og:image:height': '630'
  };

  const tags = Object.assign({}, defaults, options);

  const socialImage = tags['socialImage'];
  const extension = tags['og:image:type'];

  if (socialImage) {
    if (extension === 'image/png') {
      tags[
        'og:image'
      ] = `https://www.beanfield.com/static/img/social/${socialImage}[1200x630].png`;
      tags[
        'twitter:image'
      ] = `https://www.beanfield.com/static/img/social/${socialImage}[200x200].png`;
    } else {
      tags[
        'og:image'
      ] = `https://www.beanfield.com/static/img/social/${socialImage}[1200x630].jpg`;
      tags[
        'twitter:image'
      ] = `https://www.beanfield.com/static/img/social/${socialImage}[200x200].jpg`;
    }
  }

  tags['og:image:secure_url'] = tags['og:image'];
  tags['socialImage'] = null;

  let meta = [];
  for (const key in tags) {
    if (tags[key] && key !== 'title') {
      let tag = {
        hid: key,
        content: tags[key]
      };
      if (key.startsWith('og:')) {
        tag.property = key;
      } else {
        tag.name = key;
      }
      meta.push(tag);
    }
  }

  return {
    title: tags.title,
    meta: meta
  };
}
