import { render, staticRenderFns } from "./BusinessSearchForm.vue?vue&type=template&id=64dc1d77&scoped=true&"
import script from "./BusinessSearchForm.vue?vue&type=script&lang=ts&"
export * from "./BusinessSearchForm.vue?vue&type=script&lang=ts&"
import style0 from "./BusinessSearchForm.vue?vue&type=style&index=0&id=64dc1d77&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64dc1d77",
  null
  
)

export default component.exports