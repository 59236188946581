














































import { Mutation } from 'vuex-class';
import { Debounce } from '~/libraries/requests.ts';
import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Address } from '~/plugins/api';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class ResiAddressInput extends Vue {
  searchTerm: string = '';
  address: Address;
  isFetching: boolean = false;
  dataAddress: any = [];
  value: string;
  items: Object | null = null;

  apiKey: '';

  $refs: {
    input: HTMLFormElement;
  };
  @Mutation('businessForm/getCanadaPostApiKey')
  getCanadaPostApiKey;
  mounted() {
    this.getCanadaPostApiKey();
    this.apiKey = this.$store.state.businessForm.apiKey;
  }

  @Watch('searchTerm')
  onSearchTermChange() {
    this.isFetching = true;
    this.searchAddress();
  }

  @Debounce(500)
  async searchAddress(): Promise<void> {
    this.items = null;
    this.dataAddress = [];
    this.isFetching = true;

    if (this.searchTerm.length === 0) {
      this.isFetching = false;
    }
    if (this.searchTerm.length < 1) {
      return;
    }

    this.$axios
      .get('/search/address', { params: { q: this.searchTerm } })
      .then(response => {
        if (response.status === 200) {
          this.dataAddress = response.data;
          this.isFetching = false;
        }
      });
  }

  displayTextWidth(text) {
    let canvas: HTMLCanvasElement = document.createElement('canvas');
    let context = canvas.getContext('2d');
    if (context !== null) {
      context.font = '14px Poppins';
      let metrics = context.measureText(text);
      return metrics.width > 300;
    }
    return false;
  }
}
