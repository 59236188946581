import Vue from 'vue'
import Router from 'vue-router'

const _81d68f9a = () => import('../pages/residential-services.vue' /* webpackChunkName: "pages/residential-services" */).then(m => m.default || m)
const _3f0ab89a = () => import('../pages/wesley-clover.vue' /* webpackChunkName: "pages/wesley-clover" */).then(m => m.default || m)
const _71011c68 = () => import('../pages/why-beanfield/index.vue' /* webpackChunkName: "pages/why-beanfield/index" */).then(m => m.default || m)
const _847c065a = () => import('../pages/openface.vue' /* webpackChunkName: "pages/openface" */).then(m => m.default || m)
const _acb2f9f6 = () => import('../pages/fosc.vue' /* webpackChunkName: "pages/fosc" */).then(m => m.default || m)
const _7f6c07be = () => import('../pages/epiknetworks.vue' /* webpackChunkName: "pages/epiknetworks" */).then(m => m.default || m)
const _7b11c4d2 = () => import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */).then(m => m.default || m)
const _6c553cea = () => import('../pages/residential/index.vue' /* webpackChunkName: "pages/residential/index" */).then(m => m.default || m)
const _1fa64f00 = () => import('../pages/global-latency.vue' /* webpackChunkName: "pages/global-latency" */).then(m => m.default || m)
const _11bb2086 = () => import('../pages/fosc_redirect.vue' /* webpackChunkName: "pages/fosc_redirect" */).then(m => m.default || m)
const _5560e2ec = () => import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */).then(m => m.default || m)
const _e3d67422 = () => import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */).then(m => m.default || m)
const _24f834d7 = () => import('../pages/business/business-phone/index.vue' /* webpackChunkName: "pages/business/business-phone/index" */).then(m => m.default || m)
const _bca296ec = () => import('../pages/why-beanfield/the-beanfield-way.vue' /* webpackChunkName: "pages/why-beanfield/the-beanfield-way" */).then(m => m.default || m)
const _c23bed46 = () => import('../pages/business/business-voice-and-collaboration/index.vue' /* webpackChunkName: "pages/business/business-voice-and-collaboration/index" */).then(m => m.default || m)
const _77b32e7f = () => import('../pages/residential/do-the-things-you-want.vue' /* webpackChunkName: "pages/residential/do-the-things-you-want" */).then(m => m.default || m)
const _1d4f141e = () => import('../pages/business/partners.vue' /* webpackChunkName: "pages/business/partners" */).then(m => m.default || m)
const _4d4b9cfd = () => import('../pages/business/contact.vue' /* webpackChunkName: "pages/business/contact" */).then(m => m.default || m)
const _57fe836a = () => import('../pages/residential/support/index.vue' /* webpackChunkName: "pages/residential/support/index" */).then(m => m.default || m)
const _6488f726 = () => import('../pages/business/solutions-voix-ucaas.vue' /* webpackChunkName: "pages/business/solutions-voix-ucaas" */).then(m => m.default || m)
const _1b676b6b = () => import('../pages/business/accessibility.vue' /* webpackChunkName: "pages/business/accessibility" */).then(m => m.default || m)
const _6ec9c782 = () => import('../pages/business/private-networks.vue' /* webpackChunkName: "pages/business/private-networks" */).then(m => m.default || m)
const _e658f1ca = () => import('../pages/residential/internet/index.vue' /* webpackChunkName: "pages/residential/internet/index" */).then(m => m.default || m)
const _1a9c4288 = () => import('../pages/residential/is-beanfield-in-your-building/index.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/index" */).then(m => m.default || m)
const _6010ab07 = () => import('../pages/residential/accessibility.vue' /* webpackChunkName: "pages/residential/accessibility" */).then(m => m.default || m)
const _3acb4812 = () => import('../pages/residential/light-up-with-beanfield.vue' /* webpackChunkName: "pages/residential/light-up-with-beanfield" */).then(m => m.default || m)
const _d63dd5f8 = () => import('../pages/business/facilitez-hybride.vue' /* webpackChunkName: "pages/business/facilitez-hybride" */).then(m => m.default || m)
const _46f8eda8 = () => import('../pages/business/support.vue' /* webpackChunkName: "pages/business/support" */).then(m => m.default || m)
const _22f05d16 = () => import('../pages/residential/public-wifi/index.vue' /* webpackChunkName: "pages/residential/public-wifi/index" */).then(m => m.default || m)
const _4cdc9c5c = () => import('../pages/residential/tv/index.vue' /* webpackChunkName: "pages/residential/tv/index" */).then(m => m.default || m)
const _7733bdee = () => import('../pages/business/cloud-exchange.vue' /* webpackChunkName: "pages/business/cloud-exchange" */).then(m => m.default || m)
const _59ec92fc = () => import('../pages/business/contact-macq.vue' /* webpackChunkName: "pages/business/contact-macq" */).then(m => m.default || m)
const _7e616c50 = () => import('../pages/residential/go-steady-with-beanfield.vue' /* webpackChunkName: "pages/residential/go-steady-with-beanfield" */).then(m => m.default || m)
const _ac3db9d2 = () => import('../pages/residential/home-phone.vue' /* webpackChunkName: "pages/residential/home-phone" */).then(m => m.default || m)
const _691ec6fd = () => import('../pages/residential/work-from-home.vue' /* webpackChunkName: "pages/residential/work-from-home" */).then(m => m.default || m)
const _489275b0 = () => import('../pages/business/colocation-data-centre/index.vue' /* webpackChunkName: "pages/business/colocation-data-centre/index" */).then(m => m.default || m)
const _ba4815d8 = () => import('../pages/business/ucaas-voice-solutions.vue' /* webpackChunkName: "pages/business/ucaas-voice-solutions" */).then(m => m.default || m)
const _36f7d48e = () => import('../pages/business/business-internet/index.vue' /* webpackChunkName: "pages/business/business-internet/index" */).then(m => m.default || m)
const _ce96f938 = () => import('../pages/business/hybrid-works.vue' /* webpackChunkName: "pages/business/hybrid-works" */).then(m => m.default || m)
const _5f39f9a3 = () => import('../pages/residential/is-beanfield-in-your-building/not-on-net.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/not-on-net" */).then(m => m.default || m)
const _3ce62c9e = () => import('../pages/business/business-internet/ddos-protection.vue' /* webpackChunkName: "pages/business/business-internet/ddos-protection" */).then(m => m.default || m)
const _28ea0101 = () => import('../pages/business/colocation-data-centre/151-front-street.vue' /* webpackChunkName: "pages/business/colocation-data-centre/151-front-street" */).then(m => m.default || m)
const _50dfeb93 = () => import('../pages/residential/is-beanfield-in-your-building/not-in-system.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/not-in-system" */).then(m => m.default || m)
const _e43808fe = () => import('../pages/residential/tv/more-on-beanfield-tv.vue' /* webpackChunkName: "pages/residential/tv/more-on-beanfield-tv" */).then(m => m.default || m)
const _38188bfa = () => import('../pages/residential/is-beanfield-in-your-building/interested.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/interested" */).then(m => m.default || m)
const _600faac0 = () => import('../pages/residential/internet/reality-of-internet-speed/index.vue' /* webpackChunkName: "pages/residential/internet/reality-of-internet-speed/index" */).then(m => m.default || m)
const _37a41d39 = () => import('../pages/residential/tv/channels/index.vue' /* webpackChunkName: "pages/residential/tv/channels/index" */).then(m => m.default || m)
const _6bec9c4b = () => import('../pages/residential/tv/essentials.vue' /* webpackChunkName: "pages/residential/tv/essentials" */).then(m => m.default || m)
const _317b35e1 = () => import('../pages/business/business-voice-and-collaboration/toll-free-ivr.vue' /* webpackChunkName: "pages/business/business-voice-and-collaboration/toll-free-ivr" */).then(m => m.default || m)
const _62a1920f = () => import('../pages/residential/tv/skinny-basic.vue' /* webpackChunkName: "pages/residential/tv/skinny-basic" */).then(m => m.default || m)
const _e155b22a = () => import('../pages/business/business-voice-and-collaboration/trading-floor.vue' /* webpackChunkName: "pages/business/business-voice-and-collaboration/trading-floor" */).then(m => m.default || m)
const _7de8b14d = () => import('../pages/residential/public-wifi/remove-profile.vue' /* webpackChunkName: "pages/residential/public-wifi/remove-profile" */).then(m => m.default || m)
const _60a9cb69 = () => import('../pages/residential/is-beanfield-in-your-building/on-net.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/on-net" */).then(m => m.default || m)
const _68d3475b = () => import('../pages/business/business-phone/toll-free-ivr.vue' /* webpackChunkName: "pages/business/business-phone/toll-free-ivr" */).then(m => m.default || m)
const _52f66940 = () => import('../pages/business/colocation-data-centre/3445-park-avenue.vue' /* webpackChunkName: "pages/business/colocation-data-centre/3445-park-avenue" */).then(m => m.default || m)
const _235f712d = () => import('../pages/residential/tv/go-services.vue' /* webpackChunkName: "pages/residential/tv/go-services" */).then(m => m.default || m)
const _c9190f98 = () => import('../pages/business/business-voice-and-collaboration/hosted-voice/index.vue' /* webpackChunkName: "pages/business/business-voice-and-collaboration/hosted-voice/index" */).then(m => m.default || m)
const _95fe57c4 = () => import('../pages/business/colocation-data-centre/23-fraser-avenue.vue' /* webpackChunkName: "pages/business/colocation-data-centre/23-fraser-avenue" */).then(m => m.default || m)
const _299bdb00 = () => import('../pages/business/colocation-data-centre/905-king-street.vue' /* webpackChunkName: "pages/business/colocation-data-centre/905-king-street" */).then(m => m.default || m)
const _a40bf8ea = () => import('../pages/residential/is-beanfield-in-your-building/in-progress.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/in-progress" */).then(m => m.default || m)
const _4bf9f541 = () => import('../pages/business/colocation-data-centre/250-front-street.vue' /* webpackChunkName: "pages/business/colocation-data-centre/250-front-street" */).then(m => m.default || m)
const _43d57eec = () => import('../pages/business/business-phone/hosted-business-phone.vue' /* webpackChunkName: "pages/business/business-phone/hosted-business-phone" */).then(m => m.default || m)
const _05583d6a = () => import('../pages/residential/is-beanfield-in-your-building/on-net-order.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/on-net-order" */).then(m => m.default || m)
const _760be2cf = () => import('../pages/residential/support/faq.vue' /* webpackChunkName: "pages/residential/support/faq" */).then(m => m.default || m)
const _6c4831c2 = () => import('../pages/residential/support/beanfield-how-to-guides.vue' /* webpackChunkName: "pages/residential/support/beanfield-how-to-guides" */).then(m => m.default || m)
const _bdf3f10a = () => import('../pages/residential/is-beanfield-in-your-building/fibrestream-active.vue' /* webpackChunkName: "pages/residential/is-beanfield-in-your-building/fibrestream-active" */).then(m => m.default || m)
const _5ae1fb07 = () => import('../pages/residential/internet/how-it-works/index.vue' /* webpackChunkName: "pages/residential/internet/how-it-works/index" */).then(m => m.default || m)
const _003d020e = () => import('../pages/residential/tv/channels/view.vue' /* webpackChunkName: "pages/residential/tv/channels/view" */).then(m => m.default || m)
const _4d3383d4 = () => import('../pages/residential/internet/how-it-works/wifi-access-point.vue' /* webpackChunkName: "pages/residential/internet/how-it-works/wifi-access-point" */).then(m => m.default || m)
const _03c48d95 = () => import('../pages/business/business-voice-and-collaboration/hosted-voice/cisco-webex.vue' /* webpackChunkName: "pages/business/business-voice-and-collaboration/hosted-voice/cisco-webex" */).then(m => m.default || m)
const _7d635479 = () => import('../pages/business/business-voice-and-collaboration/hosted-voice/microsoft-teams.vue' /* webpackChunkName: "pages/business/business-voice-and-collaboration/hosted-voice/microsoft-teams" */).then(m => m.default || m)
const _1e5ae45f = () => import('../pages/residential/internet/how-it-works/fibre-router.vue' /* webpackChunkName: "pages/residential/internet/how-it-works/fibre-router" */).then(m => m.default || m)
const _64176654 = () => import('../pages/index.vue' /* webpackChunkName: "pages/index" */).then(m => m.default || m)

Vue.use(Router)


if (process.client) {
  window.history.scrollRestoration = 'manual'
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected
  if (to.matched.length < 2) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some((r) => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}


export function createRouter () {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'is-active',
    scrollBehavior,
    routes: [
		{
			path: "/residential-services",
			component: _81d68f9a,
			name: "residential-services"
		},
		{
			path: "/wesley-clover",
			component: _3f0ab89a,
			name: "wesley-clover"
		},
		{
			path: "/why-beanfield",
			component: _71011c68,
			name: "why-beanfield"
		},
		{
			path: "/openface",
			component: _847c065a,
			name: "openface"
		},
		{
			path: "/fosc",
			component: _acb2f9f6,
			name: "fosc"
		},
		{
			path: "/epiknetworks",
			component: _7f6c07be,
			name: "epiknetworks"
		},
		{
			path: "/contact-us",
			component: _7b11c4d2,
			name: "contact-us"
		},
		{
			path: "/residential",
			component: _6c553cea,
			name: "residential"
		},
		{
			path: "/global-latency",
			component: _1fa64f00,
			name: "global-latency"
		},
		{
			path: "/fosc_redirect",
			component: _11bb2086,
			name: "fosc_redirect"
		},
		{
			path: "/about-us",
			component: _5560e2ec,
			name: "about-us"
		},
		{
			path: "/business",
			component: _e3d67422,
			name: "business"
		},
		{
			path: "/business/business-phone",
			component: _24f834d7,
			name: "business-business-phone"
		},
		{
			path: "/why-beanfield/the-beanfield-way",
			component: _bca296ec,
			name: "why-beanfield-the-beanfield-way"
		},
		{
			path: "/business/business-voice-and-collaboration",
			component: _c23bed46,
			name: "business-business-voice-and-collaboration"
		},
		{
			path: "/residential/do-the-things-you-want",
			component: _77b32e7f,
			name: "residential-do-the-things-you-want"
		},
		{
			path: "/business/partners",
			component: _1d4f141e,
			name: "business-partners"
		},
		{
			path: "/business/contact",
			component: _4d4b9cfd,
			name: "business-contact"
		},
		{
			path: "/residential/support",
			component: _57fe836a,
			name: "residential-support"
		},
		{
			path: "/business/solutions-voix-ucaas",
			component: _6488f726,
			name: "business-solutions-voix-ucaas"
		},
		{
			path: "/business/accessibility",
			component: _1b676b6b,
			name: "business-accessibility"
		},
		{
			path: "/business/private-networks",
			component: _6ec9c782,
			name: "business-private-networks"
		},
		{
			path: "/residential/internet",
			component: _e658f1ca,
			name: "residential-internet"
		},
		{
			path: "/residential/is-beanfield-in-your-building",
			component: _1a9c4288,
			name: "residential-is-beanfield-in-your-building"
		},
		{
			path: "/residential/accessibility",
			component: _6010ab07,
			name: "residential-accessibility"
		},
		{
			path: "/residential/light-up-with-beanfield",
			component: _3acb4812,
			name: "residential-light-up-with-beanfield"
		},
		{
			path: "/business/facilitez-hybride",
			component: _d63dd5f8,
			name: "business-facilitez-hybride"
		},
		{
			path: "/business/support",
			component: _46f8eda8,
			name: "business-support"
		},
		{
			path: "/residential/public-wifi",
			component: _22f05d16,
			name: "residential-public-wifi"
		},
		{
			path: "/residential/tv",
			component: _4cdc9c5c,
			name: "residential-tv"
		},
		{
			path: "/business/cloud-exchange",
			component: _7733bdee,
			name: "business-cloud-exchange"
		},
		{
			path: "/business/contact-macq",
			component: _59ec92fc,
			name: "business-contact-macq"
		},
		{
			path: "/residential/go-steady-with-beanfield",
			component: _7e616c50,
			name: "residential-go-steady-with-beanfield"
		},
		{
			path: "/residential/home-phone",
			component: _ac3db9d2,
			name: "residential-home-phone"
		},
		{
			path: "/residential/work-from-home",
			component: _691ec6fd,
			name: "residential-work-from-home"
		},
		{
			path: "/business/colocation-data-centre",
			component: _489275b0,
			name: "business-colocation-data-centre"
		},
		{
			path: "/business/ucaas-voice-solutions",
			component: _ba4815d8,
			name: "business-ucaas-voice-solutions"
		},
		{
			path: "/business/business-internet",
			component: _36f7d48e,
			name: "business-business-internet"
		},
		{
			path: "/business/hybrid-works",
			component: _ce96f938,
			name: "business-hybrid-works"
		},
		{
			path: "/residential/is-beanfield-in-your-building/not-on-net",
			component: _5f39f9a3,
			name: "residential-is-beanfield-in-your-building-not-on-net"
		},
		{
			path: "/business/business-internet/ddos-protection",
			component: _3ce62c9e,
			name: "business-business-internet-ddos-protection"
		},
		{
			path: "/business/colocation-data-centre/151-front-street",
			component: _28ea0101,
			name: "business-colocation-data-centre-151-front-street"
		},
		{
			path: "/residential/is-beanfield-in-your-building/not-in-system",
			component: _50dfeb93,
			name: "residential-is-beanfield-in-your-building-not-in-system"
		},
		{
			path: "/residential/tv/more-on-beanfield-tv",
			component: _e43808fe,
			name: "residential-tv-more-on-beanfield-tv"
		},
		{
			path: "/residential/is-beanfield-in-your-building/interested",
			component: _38188bfa,
			name: "residential-is-beanfield-in-your-building-interested"
		},
		{
			path: "/residential/internet/reality-of-internet-speed",
			component: _600faac0,
			name: "residential-internet-reality-of-internet-speed"
		},
		{
			path: "/residential/tv/channels",
			component: _37a41d39,
			name: "residential-tv-channels"
		},
		{
			path: "/residential/tv/essentials",
			component: _6bec9c4b,
			name: "residential-tv-essentials"
		},
		{
			path: "/business/business-voice-and-collaboration/toll-free-ivr",
			component: _317b35e1,
			name: "business-business-voice-and-collaboration-toll-free-ivr"
		},
		{
			path: "/residential/tv/skinny-basic",
			component: _62a1920f,
			name: "residential-tv-skinny-basic"
		},
		{
			path: "/business/business-voice-and-collaboration/trading-floor",
			component: _e155b22a,
			name: "business-business-voice-and-collaboration-trading-floor"
		},
		{
			path: "/residential/public-wifi/remove-profile",
			component: _7de8b14d,
			name: "residential-public-wifi-remove-profile"
		},
		{
			path: "/residential/is-beanfield-in-your-building/on-net",
			component: _60a9cb69,
			name: "residential-is-beanfield-in-your-building-on-net"
		},
		{
			path: "/business/business-phone/toll-free-ivr",
			component: _68d3475b,
			name: "business-business-phone-toll-free-ivr"
		},
		{
			path: "/business/colocation-data-centre/3445-park-avenue",
			component: _52f66940,
			name: "business-colocation-data-centre-3445-park-avenue"
		},
		{
			path: "/residential/tv/go-services",
			component: _235f712d,
			name: "residential-tv-go-services"
		},
		{
			path: "/business/business-voice-and-collaboration/hosted-voice",
			component: _c9190f98,
			name: "business-business-voice-and-collaboration-hosted-voice"
		},
		{
			path: "/business/colocation-data-centre/23-fraser-avenue",
			component: _95fe57c4,
			name: "business-colocation-data-centre-23-fraser-avenue"
		},
		{
			path: "/business/colocation-data-centre/905-king-street",
			component: _299bdb00,
			name: "business-colocation-data-centre-905-king-street"
		},
		{
			path: "/residential/is-beanfield-in-your-building/in-progress",
			component: _a40bf8ea,
			name: "residential-is-beanfield-in-your-building-in-progress"
		},
		{
			path: "/business/colocation-data-centre/250-front-street",
			component: _4bf9f541,
			name: "business-colocation-data-centre-250-front-street"
		},
		{
			path: "/business/business-phone/hosted-business-phone",
			component: _43d57eec,
			name: "business-business-phone-hosted-business-phone"
		},
		{
			path: "/residential/is-beanfield-in-your-building/on-net-order",
			component: _05583d6a,
			name: "residential-is-beanfield-in-your-building-on-net-order"
		},
		{
			path: "/residential/support/faq",
			component: _760be2cf,
			name: "residential-support-faq"
		},
		{
			path: "/residential/support/beanfield-how-to-guides",
			component: _6c4831c2,
			name: "residential-support-beanfield-how-to-guides"
		},
		{
			path: "/residential/is-beanfield-in-your-building/fibrestream-active",
			component: _bdf3f10a,
			name: "residential-is-beanfield-in-your-building-fibrestream-active"
		},
		{
			path: "/residential/internet/how-it-works",
			component: _5ae1fb07,
			name: "residential-internet-how-it-works"
		},
		{
			path: "/residential/tv/channels/view",
			component: _003d020e,
			name: "residential-tv-channels-view"
		},
		{
			path: "/residential/internet/how-it-works/wifi-access-point",
			component: _4d3383d4,
			name: "residential-internet-how-it-works-wifi-access-point"
		},
		{
			path: "/business/business-voice-and-collaboration/hosted-voice/cisco-webex",
			component: _03c48d95,
			name: "business-business-voice-and-collaboration-hosted-voice-cisco-webex"
		},
		{
			path: "/business/business-voice-and-collaboration/hosted-voice/microsoft-teams",
			component: _7d635479,
			name: "business-business-voice-and-collaboration-hosted-voice-microsoft-teams"
		},
		{
			path: "/residential/internet/how-it-works/fibre-router",
			component: _1e5ae45f,
			name: "residential-internet-how-it-works-fibre-router"
		},
		{
			path: "/",
			component: _64176654,
			name: "index"
		}
    ],
    
    
    fallback: false
  })
}
