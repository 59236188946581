





























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Mutation } from 'vuex-class';

@Component({})
export default class extends Vue {
  @Mutation('lang/initLanguage')
  initLanguage;
  @Mutation('lang/setLanguage')
  setLanguage;

  mounted() {
    this.initLanguage();
    this.$i18n.locale = this.$store.state.lang.lang;
  }
  langActive(l) {
    if (this.$i18n.locale === l) {
      return true;
    }
  }
  lang(l) {
    this.$i18n.locale = l;
    this.setLanguage(l);
  }
}
