<script>
import TranslateResult from 'vue-i18n';
export default {
  props: {
    path: {
      type: String | TranslateResult,
      default: 'Loading'
    }
  },
  render(h) {
    return h({
      template: `<span>${this.path}</span>`
    });
  }
};
</script>
