
















































import Vue from 'vue';
import Component from 'vue-class-component';
import IconBox from '~/components/widgets/IconBox.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

@Component({
  components: {
    IconBox,
    FontAwesomeIcon
  }
})
export default class ContactUs extends Vue {
  get faPhone() {
    return faPhone;
  }
  get faEnvelope() {
    return faEnvelope;
  }
  get faCommentDots() {
    return faCommentDots;
  }
}
