























































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ContactBox, ContactBoxMultipleLinks } from '~/components/content/';
import SectionHeader from '~/components/widgets/SectionHeader.vue';
import BusinessSupportForm from '~/components/forms/BusinessSupportForm.vue';
import { TranslateResult } from '~/node_modules/vue-i18n';

@Component({
  components: {
    BusinessSupportForm,
    ContactBox,
    ContactBoxMultipleLinks,
    SectionHeader
  }
})
export default class ContactUsBusiness extends Vue {
  @Prop({
    default: ''
  })
  title: string | TranslateResult;
  @Prop({
    default: true
  })
  showSubtitle: boolean;
  @Prop({
    default: false
  })
  noMargins: boolean;
  @Prop({
    default: 'sales@beanfield.com'
  })
  email: string;
  @Prop({ default: false })
  isSupportPage: boolean;
}
