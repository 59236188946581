
































































































































































import Vue from 'vue';
import Component from 'nuxt-class-component';
import { SectionHeader } from '~/components/widgets';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Prop } from 'vue-property-decorator';
import getUTMData from '~/libraries/utm';

@Component({
  components: { SectionHeader, ValidationObserver, ValidationProvider }
})
export default class BusinessDataCentersForm extends Vue {
  @Prop()
  currentPage: string;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  companyName: string = '';
  telephone: string = '';
  subscribe: boolean = false;
  submitSuccessful: boolean | null = null;
  captchaError: boolean = false;
  $refs: {
    formRef;
  };
  async mounted() {
    await this.$recaptcha.init();
  }
  async submitForm() {
    this.captchaError = false;
    try {
      const token = await this.$recaptcha.getResponse();
      this['$axios']
        .post('/business/data-centers-contact-form', {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          companyName: this.companyName,
          telephone: this.telephone
            .replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3'),
          subscribe: this.subscribe,
          language: this.$i18n.locale,
          page: this.currentPage,
          token: token,
          ...getUTMData()
        })
        .then(res => {
          if (res.data) {
            if (res.data.successful) {
              this.firstName = this.lastName = this.companyName = this.email =
                '';
              this.telephone = '';
              this.subscribe = false;
              this.submitSuccessful = true;
              let action =
                'Sent ' +
                this.currentPage +
                ' Business Data Center Contact Form';

              this['$ga'].event({
                eventCategory: 'Business Data Center Contact Form',
                eventAction: action,
                eventLabel: this['$route'].path
              });

              this.$emit('formsubmitted');
            } else if (res.data.error) {
              this.$refs.formRef.setErrors(res.data.error_dict);
            } else {
              this.submitSuccessful = false;
            }
          } else {
            this.submitSuccessful = false;
          }
        })
        .catch(() => {
          this.submitSuccessful = false;
        });
      // reset recaptcha
      await this.$recaptcha.reset();
    } catch (error) {
      this.captchaError = true;
    }
  }
}
