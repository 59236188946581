


































import Vue from 'vue';
import Component from 'nuxt-class-component';

import { RoundButton } from '~/components/widgets';

@Component({
  components: { RoundButton }
})
export default class extends Vue {}
