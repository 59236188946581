






























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import { Mutation } from 'vuex-class';

@Component({})
export default class BannerCarousel extends Vue {
  @Prop()
  location: String;
  product: String = '';
  imageProps = {
    montreal: {
      desktop: [
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-1.png'),
          place: 'Place Vauquelin, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-2.png'),
          place: 'Jardin botanique de Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-4.png'),
          place: 'Le Village Olympique, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-5.png'),
          place: 'Débarquadère Navette Maritime, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-6.png'),
          place: 'Champ-de-Mars, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-7.png'),
          place: 'Église Erskine and American, Montréal'
        }
      ],
      mobile: [
        {
          path: require('~/assets/img/mobile/home/banner-images/montreal-mobile-1.jpg'),
          place: 'Place Vauquelin, Montréal'
        },
        {
          path: require('~/assets/img/mobile/home/banner-images/montreal-mobile-2.jpg'),
          place: 'Jardin botanique de Montréal'
        },
        {
          path: require('~/assets/img/mobile/home/banner-images/montreal-mobile-4.jpg'),
          place: 'Le Village Olympique, Montréal'
        }
      ],
      fullhd: [
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-fullhd-1.png'),
          place: 'Place Vauquelin, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-fullhd-2.png'),
          place: 'Jardin botanique de Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-fullhd-4.png'),
          place: 'Le Village Olympique, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-fullhd-5.png'),
          place: 'Débarquadère Navette Maritime, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-fullhd-6.png'),
          place: 'Champ-de-Mars, Montréal'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/montreal-desktop-fullhd-7.png'),
          place: 'Église Erskine and American, Montréal'
        }
      ]
    },
    toronto: {
      desktop: [
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-1.png'),
          place: 'Toronto Skyline'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-2.png'),
          place: 'Toronto Skyline'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-4.png'),
          place: 'Toronto Island'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-6.png'),
          place: 'Front Street & Wellington Street & Church Street, Toronto'
        }
      ],
      mobile: [
        {
          path: require('~/assets/img/mobile/home/banner-images/toronto-mobile-1.jpg'),
          place: 'Toronto Skyline'
        },
        {
          path: require('~/assets/img/mobile/home/banner-images/toronto-mobile-2.jpg'),
          place: 'Toronto Skyline'
        },
        {
          path: require('~/assets/img/mobile/home/banner-images/toronto-mobile-4.jpg'),
          place: 'Toronto Island'
        },
        {
          path: require('~/assets/img/mobile/home/banner-images/toronto-mobile-6.jpg'),
          place: 'Front Street & Wellington Street & Church Street, Toronto'
        }
      ],
      fullhd: [
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-fullhd-1.png'),
          place: 'Toronto Skyline'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-fullhd-2.png'),
          place: 'Toronto Skyline'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-fullhd-4.png'),
          place: 'Toronto Island'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/toronto-desktop-fullhd-6.png'),
          place: 'Front Street & Wellington Street & Church Street, Toronto'
        }
      ]
    },
    vancouver: {
      desktop: [
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-desktop-1.png'),
          place: 'BC Place Stadium, Vancouver'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-desktop-2.png'),
          place: 'Harbour Centre, Vancouver'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-desktop-3.png'),
          place: 'Science World, Vancouver'
        }
      ],
      mobile: [
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-mobile-1.png'),
          place: 'BC Place Stadium, Vancouver'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-mobile-2.png'),
          place: 'Harbour Centre, Vancouver'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-mobile-3.png'),
          place: 'Science World, Vancouver'
        }
      ],
      fullhd: [
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-desktop-1.png'),
          place: 'BC Place Stadium, Vancouver'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-desktop-2.png'),
          place: 'Harbour Centre, Vancouver'
        },
        {
          path: require('~/assets/img/desktop/home/banner-images/vancouver-desktop-3.png'),
          place: 'Science World, Vancouver'
        }
      ]
    }
  };
  selectedCities: object[];
  selectedCitiesMobile: object[];
  selectedCitiesFullHD: object[];

  slickOptions = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    fade: true,
    infinite: true,
    lazyLoad: 'ondemand',
    pauseOnFocus: true,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: false
  };
  $refs: {
    carousel: VueSlickCarousel;
    carouselMobile: VueSlickCarousel;
  };
  @Watch('location')
  onLocationChange() {
    this.setSelectedCities();
  }
  created() {
    this.selectedCities = this.sortCities('desktop');
    this.selectedCitiesMobile = this.sortCities('mobile');
    this.selectedCitiesFullHD = this.sortCities('fullhd');
  }
  sortCities(device) {
    if (this.location) {
      this.setSelectedCities();
    }
    let current = 'Montreal';
    let sortedCities: object[] = [];
    if (this.imageProps) {
      let torontoCount = 0;
      let montrealCount = 0;
      let vancouverCount = 0;
      let toronto = this.imageProps['toronto'][device];
      let montreal = this.imageProps['montreal'][device];
      let vancouver = this.imageProps['vancouver'][device];
      while (
        torontoCount < toronto.length &&
        montrealCount < montreal.length &&
        vancouverCount < vancouver.length
      ) {
        if (current == 'Montreal') {
          sortedCities.push(montreal[montrealCount]);
          montrealCount += 1;
          current = 'Toronto';
        } else if (current === 'Toronto') {
          if (torontoCount < toronto.length) {
            sortedCities.push(toronto[torontoCount]);
            torontoCount += 1;
            current = 'Vancouver';
          }
        } else {
          sortedCities.push(vancouver[vancouverCount]);
          vancouverCount += 1;
          current = 'Montreal';
        }
      }
    }
    return sortedCities;
  }
  setSelectedCities() {
    if (this.location == 'Montreal') {
      this.selectedCities = this.imageProps.montreal.desktop;
      this.selectedCitiesMobile = this.imageProps.montreal.mobile;
      this.selectedCitiesFullHD = this.imageProps.montreal.fullhd;
    } else if (this.location == 'Vancouver') {
      this.selectedCities = this.imageProps.vancouver.desktop;
      this.selectedCitiesMobile = this.imageProps.vancouver.mobile;
      this.selectedCitiesFullHD = this.imageProps.vancouver.fullhd;
    } else {
      this.selectedCities = this.imageProps.toronto.desktop;
      this.selectedCitiesMobile = this.imageProps.toronto.mobile;
      this.selectedCitiesFullHD = this.imageProps.toronto.fullhd;
    }
    this.$forceUpdate();
  }
  @Mutation('businessForm/setSideFormState')
  setSideFormState;
  setProduct(product) {
    this.product = product;
    this.setSideFormState(true);
    this['$ga'].event({
      eventCategory: 'Get in touch',
      eventAction: this.product,
      eventLabel: this['$route'].path
    });
  }
}
