



















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class HybridWorksLayout extends Vue {
  product: string = 'UCaaS Voice Solutions';

  setProduct(product) {
    this.product = product;
    this['$ga'].event({
      eventCategory: 'Get in Touch',
      eventAction: this.product,
      eventLabel: this['$route'].path
    });
  }

  scrollToElement() {
    const el = this.$el.getElementsByClassName('contact-form')[0];

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
