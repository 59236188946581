






















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import SectionHeader from '~/components/widgets/SectionHeader.vue';

@Component({
  components: { SectionHeader }
})
export default class CiscoFeatureImageBox extends Vue {
  @Prop()
  headerTitle: string;
  @Prop()
  description: string;
}
