































































import Vue from 'vue';
import {
  FontAwesomeIcon,
  FontAwesomeLayers
} from '@fortawesome/vue-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

export default Vue.extend({
  components: { FontAwesomeIcon, FontAwesomeLayers },
  computed: {
    faCircle() {
      return faCircle;
    },
    faFacebookF() {
      return faFacebookF;
    },
    faInstagram() {
      return faInstagram;
    },
    faLinkedin() {
      return faLinkedin;
    },
    faTwitter() {
      return faTwitter;
    }
  }
});
