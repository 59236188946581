








import Vue from 'vue';
import Component from 'nuxt-class-component';

import { Footer, SocialBar } from '~/components/layout';

@Component({
  components: { Footer, SocialBar }
})
export default class extends Vue {}
