


















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  components: {
    FontAwesomeIcon
  },
  computed: {
    faArrowRight() {
      return faArrowRight;
    }
  }
})
export default class ContactBoxMultipleLinks extends Vue {
  @Prop()
  header: string;
  @Prop()
  linkText1: string;
  @Prop()
  linkTitle1: string;
  @Prop()
  linkType1: string;
  @Prop()
  linkText2: string;
  @Prop()
  linkTitle2: string;
  @Prop()
  linkType2: string;
  @Prop()
  linkText3: string;
  @Prop()
  linkTitle3: string;
  @Prop()
  linkType3: string;
  @Prop()
  linkText4: string;
  @Prop()
  linkTitle4: string;
  @Prop()
  linkType4: string;
  @Prop({ default: false })
  disabled: boolean;
  @Prop({ default: false })
  isSupportPage: boolean;

  processLinkType(type, link) {
    let processedLink = '';
    if (type === 'phone') {
      processedLink = 'tel:' + link.replace(/\./g, '');
    } else if (type === 'email') {
      processedLink = 'mailto:' + link;
    } else {
      processedLink = link;
    }
    return processedLink;
  }
}
