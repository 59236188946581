


























































































import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

export default Vue.extend({
  components: { FontAwesomeIcon },
  computed: {
    faFacebookF() {
      return faFacebookF;
    },
    faInstagram() {
      return faInstagram;
    },
    faLinkedinIn() {
      return faLinkedinIn;
    },
    faTwitter() {
      return faTwitter;
    },
    currentYear() {
      return new Date().getFullYear();
    }
  }
});
