























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Channel, ImageLink, TvPackageApi } from '~/plugins/api';

import {
  TitleBanner,
  Breadcrumbs,
  Slogan,
  Crumb,
  ShowHide,
  ImageGroup,
  Dropdown
} from '~/components/widgets';
import { OrderOnlineBanner } from '~/components/content';
import { ContactForm } from '~/components/forms';

@Component({
  components: {
    TitleBanner,
    Breadcrumbs,
    Slogan,
    Crumb,
    ShowHide,
    ImageGroup,
    OrderOnlineBanner,
    ContactForm,
    Dropdown
  }
})
export default class extends Vue {
  @Prop()
  packageTitle: string;
  @Prop()
  crumbs: Array<Crumb>;
  @Prop()
  tvPackage: TvPackageApi;
  @Prop()
  price: string;

  getLogos(channels: Array<Channel>): Array<ImageLink> {
    return channels.map(e => ({
      src: e.logoUrl,
      alt: e.name,
      description: e.description
    }));
  }

  createPrice(price: string, frequency: string): string {
    return '$' + price + frequency;
  }
}
