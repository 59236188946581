





































import Vue from 'vue';
import Component from 'nuxt-class-component';

import { Banner } from '~/components/widgets';
import RoundButton from '~/components/widgets/RoundButton.vue';

@Component({
  components: { RoundButton, Banner }
})
export default class ReadyToGetStarted extends Vue {
  link: string = '/residential/is-beanfield-in-your-building';
  linkTitle: string = 'Order Online';
  external: boolean = false;
  orderingEnabled = process.env.ONLINE_ORDERING_ENABLED === 'true';

  mounted() {
    const address = this['$store'].state.buildingSearch.address;
    // the text keys in the json files
    let give_call_key = 'components:content:orderonlinebanner:give_call';
    let order_key = 'rw.is-beanfield-in-your-building:on-net:button-text';
    const info_text = {
      call_text: this.$t(give_call_key).toString(),
      order_text: this.$t(order_key).toString()
    };
    this.linkTitle = info_text.order_text;

    if (!this.orderingEnabled) {
      this.link = '/contact-us';
      this.linkTitle = info_text.call_text;
    } else if (address && address.isOnNet) {
      this.link = '/residential-order';
      this.linkTitle = info_text.order_text;
      this.external = true;
    }
  }
}
