



















import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';
import { Crumb } from '~/components/widgets';

@Component({})
export default class BusinessBreadcrumbs extends Vue {
  @Prop()
  crumbs: Array<Crumb>;
}
