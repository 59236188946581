






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  components: {
    FontAwesomeIcon
  },
  computed: {
    faArrowRight() {
      return faArrowRight;
    }
  }
})
export default class CiscoCollaborationBox extends Vue {
  @Prop()
  heading: string;
  @Prop()
  description: string;
}
