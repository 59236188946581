














import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class GenericTile extends Vue {
  @Prop({ default: 'rgba(255, 255, 255, 0.8)' })
  bgColor: string;
  @Prop({ default: false })
  imageOnly: boolean;
  @Prop()
  categoryTitle: string;
  @Prop()
  title: string;
  @Prop()
  tileDescription: string;
}
