



























import Vue from 'vue';
import Component from 'nuxt-class-component';
import { Prop, Provide } from 'vue-property-decorator';

@Component({})
export default class Carousel extends Vue {
  current = 0;
  @Provide()
  carousel = this;
  @Prop()
  rowCount: number;
  @Prop({ default: false })
  showPagination: boolean;
  @Prop({ default: 12000 })
  slideDelay: number;
  timeoutHandler;

  mounted() {
    this.setupTimeout();
  }

  setupTimeout() {
    this.timeoutHandler = setTimeout(() => {
      this.increaseCurrent();
    }, this.slideDelay);
  }
  getCurrent() {
    return this.current;
  }
  decreaseCurrent() {
    this.current += this.rowCount - 1;
    this.current %= this.rowCount;
    clearTimeout(this.timeoutHandler);
    this.setupTimeout();
  }
  increaseCurrent() {
    this.current += 1;
    this.current %= this.rowCount;
    clearTimeout(this.timeoutHandler);
    this.setupTimeout();
  }
}
